<template>
	<section class="content">
		<div class="row">
			<div class="col-sm-6 col-md">
				<div class="info-box flex-row-reverse">
					<span class="info-box-icon info-orange"><i class="fi fi-rr-user d-flex align-items-center"></i></span>
					<div class="info-box-content d-flex flex-column justify-content-between">
						<span class="info-box-text">Nama Shipper</span>
						<span class="info-box-number h4 mb-0 d-flex">
							<span class="text-nowrap flex-grow-1 overflow-hidden" style="width: 0px;text-overflow: ellipsis;">
								{{ shipper_name }}
							</span>
						</span>
					</div>
				</div>
			</div>
			<div class="col-sm-6 col-md">
				<div class="info-box flex-row-reverse">
					<span class="info-box-icon info-yellow"><i class="fi fi-rr-chart-line-up d-flex align-items-center"></i></span>
					<div class="info-box-content d-flex flex-column justify-content-between">
						<span class="info-box-text text-sm">Jumlah Afiliator<br />Tergabung</span>
						<span class="info-box-number h4 mb-0 d-flex">
							<span class="text-nowrap flex-grow-1 overflow-hidden" style="width: 0px;text-overflow: ellipsis;">
								{{ referralList.length }}
							</span>
						</span>
					</div>
				</div>
			</div>
			<div class="col-sm col-md" v-if="afiliator">
				<div class="info-box flex-row-reverse">
					<span class="info-box-icon info-blue"><i class="fi fi-rr-people-network-partner d-flex align-items-center"></i></span>
					<div class="info-box-content d-flex flex-column justify-content-between">
						<span class="info-box-text">Nama Afiliator</span>
						<span class="info-box-number h4 mb-0 d-flex">
							<span class="text-nowrap flex-grow-1 overflow-hidden" style="width: 0px;text-overflow: ellipsis;">
								{{ afiliator }}
							</span>
						</span>
					</div>
				</div>
			</div>
			<div class="col-sm col-md">
				<div class="info-box flex-row-reverse">
					<span class="info-box-icon info-green"><i class="fi fi-rr-wallet d-flex align-items-center"></i></span>
					<div class="info-box-content d-flex flex-column justify-content-between">
						<span class="info-box-text">Komisi Afiliator</span>
						<span class="info-box-number h4 mb-0 d-flex">
							<span class="text-nowrap flex-grow-1 overflow-hidden" style="width: 0px;text-overflow: ellipsis;">
								Rp. {{ recapAfiliasi.estimasi_reward }}
							</span>
						</span>
					</div>
				</div>
			</div>
		</div>
		<div class="row justify-content-end">
			<div class="col-12 col-sm-auto mb-3">
				<button class="btn btn-block btn-primary text-light" @click="showList">Lihat Daftar Teman</button>
			</div>
		</div>

		<div class="card">
			<div class="card-body">
				<div class="row mb-3" style="row-gap: 0.5rem;">
					<div class="col-12 col-sm-auto">
						<div class="input-group">
							<input type="text" ref="daterange" class="form-control" style="border-right: 0" />
							<div class="input-group-append">
								<div class="input-group-text" style="background-color: #fff">
									<span><span class="fi fi-rr-calendar"></span></span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-auto">
						<select class="form-control" v-model="filter.status" @change="reloadTable">
							<option value="" selected disabled hidden>Status Komisi</option>
							<option value="1">Sukses</option>
							<option value="0">Dalam Proses</option>
						</select>
					</div>
					<div class="col-auto ml-auto" v-if="false">
						<button type="button" @click="confirmRecon" :disabled="processing" class="btn btn-success">
							Rekonsiliasi
						</button>
					</div>
				</div>
				<table class="table table-hover w-100" ref="tableShipper" id="tableShipper">
					<thead>
						<tr>
							<th>Status Komisi</th>
							<th>Nama Shipper</th>
							<th>AWB Terkirim</th>
							<th>Tanggal Komisi</th>
							<th>Nilai Komisi</th>
						</tr>
					</thead>
					<tbody @click="handleClick">
					</tbody>
				</table>
				<div class="text-center" :class="{ 'd-none' : emptyShipper }">
					<img :src="getImage('empty')" width="187.5px" />
					<div class="h5 font-weight-bold">Kiriman Afiliator kosong</div>
					<p>Saat ini masih belum ada data Kiriman Afiliator</p>
				</div>
			</div>
		</div>

		<div class="modal" tabindex="-1" role="dialog" ref="affiliateIntro" data-backdrop="static">
			<div class="modal-dialog d-flex align-items-center m-auto" role="document" style="height: 100vh">
				<div class="modal-content">
					<div class="modal-header" v-if="false"></div>
					<div class="modal-body">
						<div class="d-flex justify-content-between border-bottom mb-3 pb-2">
							<div class="h5">Daftar Teman</div>
							<button class="btn btn-link m-0 p-0 text-dark" data-dismiss="modal">
								<i class="fi fi-rr-cross"></i>
							</button>
						</div>
						<div class="row my-3 border-bottom">
							<div class="col col-sm col-md"></div>
							<div class="col-8 col-sm-6 col-md-5">
								<img :src="getImage('afiliate-img')" class="w-100" />
							</div>
							<div class="col col-sm col-md"></div>
							<div class="col-12 text-center">
								<div class="h5">Ajak temanmu, nikmati komisimu!</div>
								<p>
									Dapatkan komisi saldo BosPay sebesar 1% dari ongkos kirim per paket yang dikirimkan oleh temanmu. 
									Komisi hingga jutaan rupiah. Syarat dan ketentuan berlaku.
								</p>
							</div>
						</div>
						<form class="mt-3" @submit.prevent="dontShowAgain">
							<div class="icheck-material-orange icheck-inline">
								<input type="checkbox" id="dontShowAgain" v-model="hideIntro" />
								<label for="dontShowAgain" class="ml-2 font-weight-normal">Jangan tampilkan lagi</label>
							</div>
							<button class="btn btn-block btn-warning text-light">Oke, Saya Mengerti</button>
						</form>
					</div>
					<div class="modal-footer" v-if="false"></div>
				</div>
			</div>
		</div>
		<div class="modal" tabindex="-1" role="dialog" ref="affiliateStep" data-backdrop="static">
			<div class="modal-dialog d-flex align-items-center m-auto" role="document" style="height: 100vh">
				<div class="modal-content">
					<div class="modal-header" v-if="false"></div>
					<div class="modal-body">
						<div class="d-flex justify-content-between border-bottom mb-3 pb-2">
							<div class="h5">Cara Menggunakan Kode Afiliasi</div>
							<button class="btn btn-link m-0 p-0 text-dark" data-dismiss="modal">
								<i class="fi fi-rr-cross"></i>
							</button>
						</div>
						<div class="row mt-2 pb-2 border-bottom">
							<div class="col-auto"><button class="btn btn-warning rounded-circle text-light">1</button></div>
							<div class="col d-flex align-items-center">Kirim kode link afiliasi kamu</div>
						</div>
						<div class="row mt-2 pb-2 border-bottom">
							<div class="col-auto"><button class="btn btn-warning rounded-circle text-light">2</button></div>
							<div class="col d-flex align-items-center">Pastikan teman kamu melakukan pendaftaran dengan memasukkan kode mu</div>
						</div>
						<div class="row mt-2 pb-2 border-bottom">
							<div class="col-auto"><button class="btn btn-warning rounded-circle text-light">3</button></div>
							<div class="col d-flex align-items-center">Pastikan teman kamu mengirimkan paket dari aplikasi bosCOD</div>
						</div>
						<div class="row mt-2 pb-2 border-bottom">
							<div class="col-auto"><button class="btn btn-warning rounded-circle text-light">4</button></div>
							<div class="col d-flex align-items-center">Kamu mendapatkan bonus komisi dari afiliasi teman kamu</div>
						</div>
					</div>
					<div class="modal-footer" v-if="false"></div>
				</div>
			</div>
		</div>
		<div class="modal" tabindex="-1" role="dialog" ref="affiliateList" data-backdrop="true">
			<div class="modal-dialog d-flex align-items-center m-auto" role="document" style="height: 100vh">
				<div class="modal-content">
					<div class="modal-header" v-if="false"></div>
					<div class="modal-body">
						<div class="d-flex justify-content-between" v-if="false">
							<div class="h5">Daftar Teman</div>
							<button class="btn btn-link m-0 p-0 text-dark" data-dismiss="modal">
								<i class="fi fi-rr-cross"></i>
							</button>
						</div>
						<div class="mt-2">
							<table class="table table-hover w-100" ref="tableRecap" id="tableRecap">
								<thead>
									<tr>
										<th>Nama Shipper</th>
										<th>AWB Terkirim</th>
										<th>Nilai Komisi</th>
									</tr>
								</thead>
								<tbody @click="handleClick">
								</tbody>
							</table>
							<div class="text-center" :class="{ 'd-none' : emptyRecap }">
								<img :src="getImage('afiliate-null')" width="187.5px" />
								<div class="h5 font-weight-bold">Belum Ajak Teman</div>
								<p>Kamu belum pernah ajak teman menggunakan kode afiliasi.<br />Bagikan kode afiliasi dan dapatkan komisi.</p>
							</div>
							<!-- <ul class="list-group w-100">
								<li class="list-group-item border-left-0 border-bottom-0 border-right-0 pl-2" v-if="!referralList.length">
									<div class="row">
										<div class="col col-sm col-md"></div>
										<div class="col-8 col-sm-6 col-md-5">
											<img :src="getImage('afiliate-null')" class="w-100 mb-3" />
										</div>
										<div class="col col-sm col-md"></div>
										<div class="col-12 text-center">
											<div class="h5">Belum Ajak Teman</div>
											<p>
												Kamu belum pernah ajak teman menggunakan kode afiliasi.
												Bagikan kode afiliasi dan dapatkan komisi.
											</p>
										</div>
									</div>
								</li>
								<li class="list-group-item border-left-0 border-bottom-0 border-right-0 pl-2" v-for="affiliated in referralList">
									<div class="row">
										<div class="col-auto"><img :src="'https://ui-avatars.com/api/?name=' + affiliated.nama + '&background=random&color=fff&size=32&length=1&rounded=true&bold-true'" /></div>
										<div class="col">
											<div class="font-weight-bold">{{ affiliated.nama }}</div>
											<small class="text-grey">Bergabung pada {{ shortDate(affiliated.created_on) }}</small>
										</div>
									</div>
								</li>
							</ul> -->
						</div>
					</div>
					<div class="modal-footer" v-if="false"></div>
				</div>
			</div>
		</div>
		<!-- Form Rekonsiliasi Afiliasi -->
		<div class="modal" tabindex="-1" role="dialog" ref="formPreview" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<form role="form" @submit.prevent="submitForm">
					<div class="modal-content">
						<div class="modal-header">
							<h5>KONFIRMASI REKONSILIASI</h5>
						</div>
						<div class="modal-body">
							<div class="col-7 text-center mx-auto mb-2">
								<h5>Periode :</h5>
								<datepicker v-model="formItem.month" @input="loadTotalAfiliasi" :options="{format:'mm/yyyy', startView: 'months', minViewMode: 'months', autoclose: true}"/>
							</div>
							<div class="col-7 bank card bg-for-light padding mx-auto">
								<h6>Total kiriman : {{ !formItem.total_kiriman ? 0 : formItem.total_kiriman }}</h6>
								<h6>Estimasi diterima : Rp {{ formatPrice(formItem.total_rekon) }}</h6>
								</div>
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-primary">
								Konfirmasi
								<span v-if="processing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							</button>
							<button type="button" class="btn btn-secondary" data-dismiss="modal">
								Tutup
							</button>
						</div>
						<div class="mb-2"></div>
					</div>
				</form>
			</div>
		</div>
	</section>
	<!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth, formatCurrency } from "@/libs/hxcore";
import $ from "jquery";
import "icheck-material";
import moment from "moment";
import Daterangepicker from "daterangepicker";
import datepicker from '@/components/Datepicker';
import "daterangepicker/daterangepicker.css";
import Swal from "sweetalert2";

export default {
	name: "ShipperAfiliasi",
	data() {
		return {
			errors: [],
			method: "",
			roles: "",
			groups: [],
			memberType: [],
			userCS: [],
			bank: [],
			filter: {
				shipper_id: 0,
				dt1: moment().startOf("month").format("YYYY-MM-DD"),
				dt2: moment().endOf("month").format("YYYY-MM-DD"),
				status: ''
			},
			recapAfiliasi: {
				estimasi_reward: 0
			},
			shipper_name: 'Memuat',
			afiliator: '',
			disabled: false,
			processing: false,
			formItem: {
					shipper_id: 0,
					month: moment().format("MM/YYYY"),
					total_kiriman: 0,
					total_rekon: 0
			},
			hideIntro: false,
			referralList: [],
			referralCode: "",
			emptyShipper: false,
			emptyRecap: false
		};
	},
	components: {
		datepicker
	},
	created: function () {
		this.roles = this.$route.meta.roles;
		this.userInfo = auth.user();
		this.filter.shipper_id = this.$route.params.id;
	},
	methods: {
		shortDate: function (date) {
			return moment(date).format('DD/MM/YYYY');
		},
		dontShowAgain: function (ev) {
			$(this.$refs.affiliateIntro).modal("show");
			localStorage.hideIntro = this.hideIntro;
			ev.preventDefault();
		},
		getImage(file) {
			return require(`../assets/img/${file}.svg`);
		},
		handleClick(e) {
			// if (e.target.matches(".link-")) {
			// 	this.$router.push({ path: "/" + e.target.dataset.id });
			// 	return false;
			// }
		},
		loadTotalAfiliasi(month){
			this.formItem.month = month;

			// set data total reward per month
			var params = { shipper_id: this.filter.shipper_id, month: this.formItem.month };
			let data = new URLSearchParams(params);

			// get data total reward
			let url = '/shipper/total_reward_afiliasi?'+ data;
			authFetch(url)
			.then((res) => {
				if (res.status === 201) {
				} else if (res.status === 400) {
				}
				return res.json();
			})
			.then((js) => {
				if (js.data == null) this.formItem = {};
				else this.formItem = js.data;
			});
		},
		showStep: function () {
			const e = this.$refs;
			$(e.affiliateStep).modal("show");
		},
		showList: function () {
			if (this.subTable) {
				this.subTable.api().ajax.reload();
			} else {
				const e = this.$refs;
				const self = this;
				this.subTable = createTable(e.tableRecap, {
					ajax: "/shipper/affiliasi/recap_afiliasi_by_id",
					frame: false,
					scrollX: true,
					processing: true,
					filter: false,
					displayLength: 5,
					lengthChange: false,
					language: {
						processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
					paramData: function (d) {
						d.dt1 = self.dateStart;
						d.dt2 = self.dateEnd;
						d.shipper_id = self.filter.shipper_id;
						var filter = JSON.parse(JSON.stringify(self.filter));
						for (const key in filter) {
							d[key] = filter[key] === false ? 0 : filter[key];
						}
					},
					columns: [
						{ data: "nama",
						render: function (data, type, row, meta) {
							return data;
							// return `<div class="row">
							// 			<div class="col-auto"><img src="https://ui-avatars.com/api/?name=${data}&background=random&color=fff&size=15&length=1&rounded=true&bold-true'" /></div>
							// 			<div class="col pl-0">${data}</div>
							// 		</div>`;
						}, },
						{ data: "joined_on",
						render: function (data, type, row, meta) {
							return moment(row.created_on).format("DD/MM/YYYY\u00A0HH:mm");
						}, },
						{ data: "total_reward", 
							className: "text-right",
							render: function (data, type, row, meta) {
								var total_reward = data ? data : 0;
								return `Rp.\u00A0${formatCurrency(total_reward)}`;
						}, },
					],
					drawCallback: function (row, data) {
						self.emptyRecap = row._iRecordsTotal > 0;
						$(e.affiliateList).modal("show");
					},
				});
			}
		},
		confirmRecon: function () {
			const er = this.$refs;
			$(er.formPreview).modal("show");
		},
		submitForm: function (ev) {
			const er = this.$refs;
			// const self = this;
			if (!this.formItem.month) {
				Swal.fire("Periode belum dipilih!", ``, "error");
			} else {
				this.formItem.shipper_id = this.filter.shipper_id;
				var data = Object.keys(this.formItem)
				.map(
					(key) =>
					encodeURIComponent(key) + "=" + encodeURIComponent(this.formItem[key])
				)
				.join("&");
				this.processing = true;
				Swal.fire({
						title: "Konfirmasi Rekonsiliasi?",
						showCancelButton: true,
						confirmButtonText: `Ya`,
						cancelButtonText: "Tidak",
				}).then((result) => {
					if (result.isConfirmed) {
						authFetch("/shipper/rekonsiliasi_afiliasi", {
							method: 'PUT',
							body: data
						})
						.then((res) => {
							return res.json();
						})
						.then((js) => {
							this.processing = false;
							if (js.success) {
									Swal.fire("Proses Berhasil", ``, "success");
									$(er.formPreview).modal("hide");
									this.loadAllStatus(this.filter);
									this.mainTable.api().ajax.reload();
							} else {
									Swal.fire("Proses gagal", ``, "error");
									// this.tbl.api().ajax.reload();
							}
						});
					}
				});
			}
			ev.preventDefault();
		},
		formatPrice(val){
				if (!val || val == 0) return "0";
				return formatCurrency(val);
		},
		reloadTable() {
			this.mainTable.api().ajax.reload();
		},
		loadAllStatus(params) {
			// console.log("params ", Object.keys(params));
			var data = '';
			if (!params) {
				data = '';
			} else {
				data = Object.keys(params)
					.map(
						(key) => encodeURIComponent(key) + "=" + ((params[key] ===false || params[key] === undefined)?0 : encodeURIComponent(params[key]))
					)
					.join("&");
			}
			// console.log(data);

			let url = '/shipper/affiliasi/recap_afiliasi?'+ data;
			authFetch(url)
			.then((res) => {
				if (res.status === 201) {
				} else if (res.status === 400) {
				}
				return res.json();
			})
			.then((js) => {
				this.recapAfiliasi = js.data;
				this.recapAfiliasi.estimasi_reward = formatCurrency(js.data.estimasi_reward);
			});
		},
	},
	mounted() {
		const e = this.$refs;
		var self = this;
		new Daterangepicker(
			this.$refs.daterange,
			{
				startDate: !this.dt1 ? moment().day(-31) : this.dt1,
				endDate: !this.dt2 ? moment() : this.dt2,
				locale: {
					format: 'DD/MM/YYYY'
				},
			},
			function (d1, d2) {
				self.dateStart = d1.format("YYYY-MM-DD");
				self.dateEnd = d2.format("YYYY-MM-DD");
				self.data_type = self.data_type;
				self.filter.datatype = self.data_type;
				self.filter.dt1 = self.dateStart;
				self.filter.dt2 = self.dateEnd;
				self.reloadTable();
			}
		);
		authFetch("/users/profile/affiliated" + (this.$route.params.id ? '/' + this.$route.params.id : ''))
		.then((res) => {
			if (res.status === 201) {} else if (res.status === 400) {}
			return res.json();
		})
		.then((js) => {
			if (js.success) {
				this.shipper_name = js.name;
				this.afiliator = js.afiliator;
				this.referralList = js.data;
			} else {
				Swal.fire("Kamu belum berhasil ajak satu pun teman", ``, "error");
			}
		});

		this.mainTable = createTable(e.tableShipper, {
			ajax: "/shipper/affiliasi/afiliasi_by_id",
			frame: false,
			scrollX: true,
			processing: true,
			filter: true,
			filterBy: [ 1, 2 ],
			order: [[ 3, 'desc' ]],
			language: {
				processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
			paramData: function (d) {
				d.dt1 = self.dateStart;
				d.dt2 = self.dateEnd;
				d.shipper_id = self.filter.shipper_id;
				var filter = JSON.parse(JSON.stringify(self.filter));
				for (const key in filter) {
					d[key] = filter[key] === false ? 0 : filter[key];
				}
			},
			columns: [
				{ data: "payment_status",width: '12%',
				sortable: false,
				render: function (data, type, row, meta) {
					if (data == 1) {
						return '<span class="badge badge-success">Sukses</span>'
					} else if(data == 0) {
						return '<span class="badge badge-warning">Dalam Proses</span>'
					}
				} },
				{ data: "nama",
				render: function (data, type, row, meta) {
					return data + `<small class="d-block">ID : ${row.shipper_code}</small>`;
				}, },
				{ data: "no_resi"},
				{ data: "joined_on",
				render: function (data, type, row, meta) {
					return moment(row.created_on).format("DD/MM/YYYY HH:mm");
				}, },
				{ data: "total_reward", 
					className: "text-right",
					render: function (data, type, row, meta) {
						var total_reward = data ? data : 0;
						return `Rp.\u00A0${formatCurrency(total_reward)}`;
				}, },
			],
			drawCallback: function (row, data) {
				self.filter.dt1 = moment(self.filter.dt1).format("YYYY-MM-DD");
				self.filter.dt2 = moment(self.filter.dt2).format("YYYY-MM-DD");
				self.loadAllStatus(self.filter);
				self.emptyShipper = row._iRecordsTotal > 0;
				if (!localStorage.hideIntro && auth.user().role_id > 10) {
					$(e.affiliateIntro).modal("show");
				}
			}
		});
	},
};
</script>
<style type="text/css">
	@media (min-width: 784px) {
		.w-49 {
			margin-left: 0.5rem !important;
			width: auto !important;
			height: fit-content;
		}
	}
	.dataTables_scrollHeadInner {
		width: 100% !important;
	}
</style>