<template>
	<section class="content">
		<div class="card">
			<div class="card-body">
				<div class="row mb-3 justify-content-end" style="row-gap: .5rem;">
					<div class="col-12 col-sm-auto mr-auto mr-sm-0" v-if="path === 'approver'">
						<label class="control-label my-2 ml-2 font-weight-normal">Filter Tanggal :</label>
					</div>
					<div class="col-12 col-sm-auto mr-auto" :class="{ 'mr-sm-0' : false }">
						<div class="input-group">
							<input type="text" ref="daterange" class="form-control border-right-0" />
							<div class="input-group-append">
								<div class="input-group-text rounded-right d-flex align-items-center border-left-0 pl-1 bg-white">
									<i class="fi fi-rr-calendar d-flex"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="col-auto col-sm-auto">
						<div class="input-group cursor-pointer" @click="selectFilter">
							<span class="form-control border-right-0">Filter</span>
							<div class="input-group-append">
								<div class="input-group-text rounded-right d-flex align-items-center border-left-0 pl-1 bg-white">
									<i class="fi fi-rr-filter d-flex"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="col col-sm-auto text-right">
						<div class="btn-group" ref="toolbar">
							<button type="button" data-action="read" class="btn btn-outline-primary border-right-0" v-if="false">
								<i class="fi fi-rr-refresh d-flex"></i>
							</button>
							<button type="button" :disabled="loading" v-on:click="downloadXlsx" data-action="export-xlsx" class="btn" :class="( path === 'approver' ? 'btn-outline-primary' : 'btn-primary' )">
								<span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
								<i v-else class="fi fi-rr-document"></i> Download Jurnal
							</button>
						</div>
					</div>
				</div>

				<div class="row mb-3" style="row-gap: 15px;" v-if="filter_status.length > 0">
					<div class="col-auto" v-for="data in filter_status">
						<span class="btn border border-secondary rounded-pill pr-2 text-secondary">
							{{ data.label }} <span class="cursor-pointer" @click="removeFilterStatus(data.id)">&#128473;</span>
						</span>
					</div>
					<div class="col-auto" v-if="filter_status.length > 1 || filter_status.length > 0">
						<span class="btn btn-link px-0 text-secondary cursor-pointer font-weight-bold" @click="clearFilter">
							Hapus Semua
						</span>
					</div>
				</div>

				<div class="btn-area mb-3" ref="btnSelected" v-if="selectedCount > 0">
					<button type="button" class="btn btn-success" @click="confirmRequest(1)"><i class="fi fi-rr-check"></i> Setujui ({{ selectedCount }})</button>
					<button type="button" class="btn btn-danger" @click="confirmRequest(2)"><i class="fi fi-rr-cross"></i> Tolak ({{ selectedCount }})</button>
				</div>

				<table class="table table-hover w-100" id="tableRequestApproval" ref="tblrequestjurnal">
					<thead>
						<tr>
							<th>
								<input type="checkbox" id="checkall" ref="checkall" name="checkall" value="1" @click="selectAll" />
							</th>
							<th>Tanggal</th>
							<th>ID Shipper</th>
							<th>Nama Shipper</th>
							<th>Referensi</th>
							<th>Note</th>
							<th>Created by</th>
							<th>Updated by</th>
							<th>Nominal</th>
							<th>Status</th>
							<th>Aksi</th>
						</tr>
					</thead>
					<tbody @click="handleClick"></tbody>
				</table>
				<div class="text-center" :class="{ 'd-none' : emptyNotes }">
					<img :src="getImage('empty')" width="187.5px" />
					<div class="h5 font-weight-bold">Request Approval kosong</div>
					<p>Saat ini masih belum ada data request approval, tambahkan request baru</p>
				</div>
			</div>
		</div>

		<div class="modal" tabindex="-1" role="dialog" ref="filterDialog" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form @submit.prevent="getFilter($event)">
						<div class="modal-header">
							<h5 class="modal-title font-weight-bold">Filter Request Approval</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="form-group m-2 p-3 bg-light rounded">
								<div class="row">
									<div class="col d-flex justify-content-between">
										<label class="h6">Status</label>
									</div>
								</div>
								<div class="grid grid-auto-flow-column" style="--bs-rows: 1;--bs-columns: 3;">
									<div v-for="data in listStatus">
										<div class="icheck-material-orange icheck-inline form-check w-100">
											<input class="form-check-input" type="checkbox" :id="data.id" :value="data.id" v-model="filter.status" />
											<label class="form-check-label text-capitalize w-100" :for="data.id">{{ data.label }}</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-outline-primary" data-dismiss="modal">Batal</button>
							<button type="submit" class="btn btn-primary" :disabled="loading">Cari</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<Pin :showPin="showPin" :loadingPin="loading" :messagePin="messagePin" v-on:change="submitRequest" />
	</section>
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import moment from "moment";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import Pin from "@/dialog/Pin";
import "icheck-material";
import $ from "jquery";

export default {
	name: "JurnalRequest",
	data() {
		return {
			path: '',
			dt1: moment().startOf("month"),
			dt2: moment(),
			emptyNotes: false,
			loading: false,
			roles: "",
			confirm: 0,
			request_confirm: '',
			selectedCount: 0,
			selectedItems: [],
			listStatus: [
				{
					id: '0',
					label: 'Menunggu'
				}, 
				{
					id: '1',
					label: 'Disetujui'
				}, 
				{
					id: '2',
					label: 'Ditolak'
				}
			],
			filter_status: [],
			filter: {
				status: []
			},
			showPin: false,
			messagePin: 'request approval bospay'
		};
	},
	created: function () {
		this.roles = this.$route.meta.roles;

		if (this.$route.params.filter) {
			this.filter = this.$route.params.filter;
		}

		if (this.filter.dt1 && this.filter.dt2) {
			this.dt1 = moment(this.filter.dt1);
			this.dt2 = moment(this.filter.dt2);
		}

		this.dateStart = this.dt1.format('YYYY-MM-DD');
		this.dateEnd = this.dt2.format('YYYY-MM-DD');

		if (this.filter.gid) {
			this.groupId = this.filter.gid;
			this.dateStart = '';
			this.dateEnd = '';
		}

		if (Object.keys(this.filter).length<1) {
			if (sessionStorage.filterData) {
				this.filter = JSON.parse(sessionStorage.filterData);
			}
		} else {
			sessionStorage.filterData = JSON.stringify(this.filter);
			sessionStorage.filterStatus = 1;
		}

		this.path = this.$route.meta.type;
	},
	methods: {
		handleClick(e) {
			if (e.target.matches(".request-detail")) {
				let route = this.$router.resolve({
					path: "/report/form-jurnal/request/" + e.target.dataset.id
				});
				window.open(route.href, "_blank");
			} else if (e.target.matches(".confirm-request")) {
				const checkList = document.querySelectorAll(".select-request");
				for (let i = 0; i < checkList.length; i++) {
					checkList[i].checked = false;
				}
				document.querySelectorAll(".select-request[value='" + e.target.dataset.id + "']")[0].checked = true;
				this.confirmRequest(e.target.dataset.action);
			} else if (e.target.matches("input[type=checkbox]")) {
				var checkboxes = document.querySelectorAll('.select-request:checked');
				if (checkboxes.length == 0) {
					this.$refs.checkall.checked = false;
				} else {
					this.$refs.checkall.checked = true;
				}
				this.selectedCount = checkboxes.length;
			}
		},
		getImage(file) {
			return require(`../assets/img/${file}.svg`); // the module request
		},
		selectFilter() {
			$(this.$refs.filterDialog).modal("toggle");
		},
		getFilter(event) {
			this.filter_status = [];
			for (const key of this.filter.status) {
				this.filter_status.push(this.listStatus.find((e) => e.id == key));
			}
			this.table.api().ajax.reload();
			$(this.$refs.filterDialog).modal("toggle");
		},
		removeFilterStatus(value) {
			this.filter_status = this.filter_status.filter((e) => e.id !== value);
			this.filter.status = this.filter.status.filter((e) => e !== value);
			this.table.api().ajax.reload();
		},
		clearFilter() {
			this.filter.status = [];
			this.filter_status = [];
			this.table.api().ajax.reload();
		},
		selectAll: function (e) {
			var val = e.target.checked;
			var checkboxes = document.querySelectorAll('.select-request');

			for (var i = 0; i < checkboxes.length; i++) {
				checkboxes[i].checked = val;
			}

			if (val && checkboxes.length > 0) {
				this.selectedCount = checkboxes.length;
			} else {
				this.selectedCount = 0;
			}

			if (this.selectedCount > 0) {
				$(this.$refs.btnSelected).show();
			} else {
				$(this.$refs.btnSelected).hide();
			}
		},
		confirmRequest: async function (confirm) {
			this.confirm = confirm;

			const { value: requestConfirm } = await this.customSwal.fire({
				html: '<img src="' + this.getImage(confirm == 1 ? 'approve' : confirm == 2 ? 'reject' : 'approve') + '" class="swal2-ico d-block mx-auto mb-3" /><div class="h5">Yakin ingin ' + (confirm == 1 ? 'setujui' : confirm == 2 ? 'tolak' : 'tunggu') + ' request approval bospay?</div>',
				input: confirm == 2 ? 'text' : '',
				inputPlaceholder: 'Ketik alasan penolakan',
				showDenyButton: true,
				confirmButtonText: confirm == 1 ? 'Setujui' : confirm == 2 ? 'Tolak' : 'Tunggu',
				denyButtonText: 'Batal',
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (value != '') {
							resolve();
						} else {
							resolve('Alasan penolakan belum di-isi');
						}
					})
				}
			});

			if (requestConfirm) {
				this.request_confirm = requestConfirm;
				this.checkPin();
			} else {
				this.loading = false;
			}
		},
		checkPin: async function () {
			await authFetch("/users/isset_pin")
			.then((response) => response.json())
			.then((json) => {
				if (json.empty) {
					this.customSwal.fire({
						html: '<img src="' + this.getImage('lock') + '" class="swal2-ico d-block mx-auto mb-3" /><div class="h5">Akun kamu belum pernah membuat PIN.<br>Buat PIN dahulu untuk melanjutkan proses approval.</div>',
						showDenyButton: true,
						confirmButtonText: 'Buat PIN',
						denyButtonText: 'Batal',
						allowOutsideClick: false
					}).then((result) => {
						if (result.isConfirmed) {
							this.$router.push({ path: '/settings/pin' });
						}
						return;
					});
				} else {
					this.showPin = !this.showPin;
				}
			});
		},
		submitRequest: function (pinSet) {
			this.loading = true;
			var checkboxes = document.querySelectorAll('.select-request');
			var selected = [];

			for (var i = 0; i < checkboxes.length; i++) {
				if (checkboxes[i].checked){
					var exp = checkboxes[i].value.split(';');
					selected.push(exp[0]);
				}
			}

			this.selectedItems = selected;
			authFetch("/accounting/jurnal/confirm_bospay", {
				method: 'POST',
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ journal_no: this.selectedItems, status: this.confirm, reason: this.request_confirm, pin_no: pinSet })
			})
			.then((response) => response.json())
			.then((json) => {
				this.loading = false;
				if (json.success) {
					this.customSwal.fire({
						html: '<img src="' + this.getImage('ok') + '" class="w-50 d-block mx-auto mb-3" /><div class="h5">Berhasil ' + (this.confirm == 1 ? 'menyetujui' : this.confirm == 2 ? 'menolak' : 'menunggu') + ' request approval bospay</div>',
						submitButtonText: 'Kembali'
					});
					this.table.api().ajax.reload(null, false);
				} else {
					this.customSwal.fire({
						icon: 'error',
						html: '<div class="h5">' + (json.message ? json.message : 'Proses gagal') + '</div>',
						submitButtonText: 'Coba lagi'
					});
				}
			});
		},
		downloadXlsx: function (e) {
			var table = $('#tableRequestApproval').DataTable();
			var length = table.page.info().recordsTotal;

			if (length > 20000) {
				Swal.fire("Proses gagal", `Maksimal data Request sebanyak kurang lebih 20.000 data.`, "error");
				return false;
			}

			this.filter.dt1 = this.dateStart;
			this.filter.dt2 = this.dateEnd;

			var filter = JSON.parse(JSON.stringify(this.filter));
				filter.q = $("input[name=q]").val();
				filter.category = $("select[name=category]").val();

			console.log(filter);
			var data = Object.keys(filter)
				.map(
					(key) =>
					encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
				)
				.join("&");
			console.log(data);
			this.loading = true;
			authFetch("/report/bospay/request_approval_excell", {
				method: "POST",
				body: data,
			})
			.then((response) => {
				if (response.status === 201) {
				} else if (response.status === 400) {
				} else if (response.status === 500){
					Swal.fire("Timeout!", ``, "error");
					return false;
				}

				return response.blob();
			})
			.then((blob) => {
				this.loading = false;
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement("a");
				a.href = url;
				a.download = "Request Approval Bospay.xlsx";
				document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
				a.click();
				a.remove(); //afterwards we remove the element again
			});
		},
		showTable: function() {
			const e = this.$refs;
			var self = this;

			this.table = createTable(e.tblrequestjurnal, {
				roles: this.$route.params.roles,
				ajax: "/accounting/jurnal/request_approval_bospay",
				frame: false,
				toolbarEl: e.toolbar,
				scrollX: true,
				processing: true,
				language: {
				processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
				filterBy: [2, 4, 5],
				order: [[0, 'desc']],
				paramData: function (d) {
					var filter = JSON.parse(JSON.stringify(self.filter));
					//d = filter;
					//d.dev = filter.delivered;
					d.dt1 = self.dateStart;
					d.dt2 = self.dateEnd;
					var filter = JSON.parse(JSON.stringify(self.filter));
					for (const key in filter) {
							d[key] = filter[key] === false ? 0 : filter[key];
					}
				},
				columns: [
					{
						data: "id",
						visible: self.path === 'approver',
						sortable: false,
						render: function (data, type, row, meta) {
							return row.status == '0' ? '<input type="checkbox" class="select-request" value="' + data + '" />' : '';
						}
					},
					{ data: "journal_date" },
					{ data: "shipper_code", visible: false },
					{
						data: "nama",
						render: function (data, type, row, meta) {
							return data + (row.shipper_code ? ` (${row.shipper_code})` : '');
						}
					},
					{ data: "referensi" },
					{ data: "note" },
					{
						data: "created_on",
						render: function (data, type, row, meta) {
							return row.creator ? data + '\u00A0\u2014\u00A0' + row.creator : '\u2013';
						}
					},
					{
						data: "updated_on",
						render: function (data, type, row, meta) {
							return row.updater ? data + '\u00A0\u2014\u00A0' + row.updater : '\u2013';
						}
					},
					{
						data: null,
						render: function (data, type, row, meta) {
							var minus = (row.credit - row.debit) < 0;
							var formatRupiah = (minus ? '-' : '') + 'Rp\u00A0' + formatCurrency(parseInt(row.credit) + parseInt(row.debit));
							return '<font color="' + (minus ? 'red' : 'black') + '">' + formatRupiah + '</font>';
						}
					},
					{
						data: "status",
						render: function (data, type, row, meta) {
							var status = ['Menunggu', 'Disetujui', 'Ditolak'];
							var color = ['info', 'success', 'danger'];
							return '<span class="badge badge-' + color[data] + '">' + status[data] + '</span>';
						}
					},
					{
						data: "id",
						// visible: self.path === 'approver',
						sortable: false,
						render: function (data, type, row, meta) {
							return `<div class="d-inline-flex align-items-center" style="gap: 7.5px">
								<i class="fi fi-rr-eye h5 mb-0 text-primary cursor-pointer request-detail" data-id="${data}"></i>
								<button class="btn btn-success btn-sm confirm-request ${row.status == '0' && self.path === 'approver' ? 'd-inline' : 'd-none'}" data-action="1" data-id="${data}">Setujui</button>
								<button class="btn btn-danger btn-sm confirm-request ${row.status == '0' && self.path === 'approver' ? 'd-inline' : 'd-none'}" data-action="2" data-id="${data}">Tolak</button>
							</div>`;
						},
					},
				],
				drawCallback: function (row, data) {
					self.emptyNotes = row._iRecordsTotal > 0;
					e.checkall.checked = false;
				},
				initComplete: function () {
					$('.loading-overlay').removeClass('show');
				},
			});
		}
	},
	mounted() {
		const e = this.$refs;
		var self = this;

		new Daterangepicker(
			this.$refs.daterange,
			{
				startDate: !this.dt1 ? moment().day(-31) : this.dt1,
				endDate: !this.dt2 ? moment() : this.dt2,
				locale: {
					format: 'DD/MM/YYYY'
				},
			},
			function (d1, d2) {
				self.dateStart = d1.format("YYYY-MM-DD");
				self.dateEnd = d2.format("YYYY-MM-DD");
				self.filter.dt1 = self.dateStart;
				self.filter.dt2 = self.dateEnd;
				self.table.api().ajax.reload();
			}
		);

		this.customSwal = Swal.mixin({
			customClass: {
				content: 'p-0',
				title: 'h4 tex-dark',
				input: 'form-control',
				actions: 'd-flex justify-content-between flex-row-reverse',
				confirmButton: 'btn btn-primary col font-weight-bold',
				denyButton: 'btn btn-outline-primary col font-weight-bold',
				cancelButton: 'btn btn-primary w-75 font-weight-bold m-auto',
			},
			buttonsStyling: false
		});

		authFetch("/accounting/jurnal/user_approval_bospay")
		.then((response) => response.json())
		.then((json) => {
			if (json.success) {
				if (this.$route.path === '/report/jurnal-request') {
					this.$router.push({ path: '/report/jurnal-approve' });
					this.path = 'approver';
				}
			} else {
				if (this.$route.path === '/report/jurnal-approve') {
					this.$router.push({ path: '/report/jurnal-request' });
					this.path = 'maker';
				}
			}
			this.showTable();
		});
	},
	components: {
		Pin
	}
};
</script>