<template>
	<section class="content">
		<div class="card-back w-100">
			<div class="card card-primary card-mx-auto">
				<div class="nav border-bottom">
					<div class="nav-item cursor-pointer" @click="changeMenu('service')">
						<h6 class="nav-link m-2 font-weight-bold" :class="(tabmenu == 'service' ? 'active text-primary' : 'text-secondary')">Data Service Kurir</h6>
					</div>
					<div class="nav-item cursor-pointer" @click="changeMenu('logs')">
						<h6 class="nav-link m-2 font-weight-bold" :class="(tabmenu == 'logs' ? 'active text-primary' : 'text-secondary')">Riwayat Perubahan</h6>
					</div>
					<div class="nav-item cursor-pointer d-flex align-items-center ml-auto mr-2" v-if="tabmenu == 'service'">
						<div class="btn-group" ref="toolbar">
							<button type="button" :disabled="downloading" v-on:click="downloadXlsx" data-action="export-xlsx" class="btn btn-primary">
								<span v-if="downloading" class="spinner-border spinner-border-sm"></span>
								<i v-else class="fi fi-rr-file-download"></i> 
								<span class="d-none d-md-inline-block ml-1">Download</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div :hidden="!(tabmenu == 'service')">
			<table class="table" ref="tablecourierservice" style="min-width: 100%;">
				<thead>
					<tr>
						<th>Service Code</th>
						<th>Kurir</th>
						<th>Percent Cashback</th>
						<th>Note</th>
					</tr>
				</thead>
				<tbody @click="handleClick"></tbody>
			</table>
		</div>

		<div :hidden="!(tabmenu == 'logs')">
			<table class="table table-hover row-border" ref="tablelogs">
				<thead>
					<tr>
						<th>Kurir Service</th>
						<th>Jenis Perubahan</th>
						<th>Tanggal Perubahan</th>
						<th>Approved By</th>
						<th>Sebelum Perubahan</th>
						<th>Setelah Perubahan</th>
					</tr>
				</thead>
				<tbody></tbody>
			</table>
			<div class="text-center" :class="{ 'd-none' : emptyLogs }">
				<img :src="getImage('empty')" class="w-25" />
				<div class="h5 font-weight-bold">Riwayat perubahan kosong {{ emptyLogs }}</div>
				<p>Tidak ada riwayat perubahan pada rentang waktu ini atau pada filter yang kamu pilih</p>
			</div>
		</div>

		<div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form role="form" @submit="submitForm">
						<div class="modal-header">
							<h5 class="modal-title">{{ formTitle }}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
								<b>Please correct the following error(s):</b>
								<ul>
									<li v-for="error in errors" v-bind:key="error">{{ error }}</li>
								</ul>
								<button type="button" class="close" data-dismiss="alert" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</p>
							<div class="row">
								<div class="col-sm-6 form-group">
									<label class="control-label">Service Code</label>
									<input id="service_code" class="form-control" v-model="form.service_code" type="text" required="required" name="service_code" :readonly="this.method == 'PUT'" />
								</div>
								<div class="col-sm-6 form-group">
									<label class="control-label">Kurir</label>
									<select v-model="form.courier_id" id="courier_id" name="courier_id" class="form-control" :disabled="this.method == 'PUT'">
										<option v-for="courier in groups" v-bind:key="courier.courier_id" v-bind:value="courier.courier_id">
											{{ courier.label }}
										</option>
									</select>
								</div>
								<div class="col-sm-6 form-group">
									<label class="control-label">Percent Cashback</label>
									<input type="hidden" name="row_id" value="" />
									<input id="percent_cashback" class="form-control" v-model="form.percent_cashback" type="number" required="required" name="percent_cashback" />
								</div>
								<div class="col-sm-6 form-group">
									<label class="control-label">Fee COD Shipper</label>
									<input id="feecod_shipper" class="form-control" v-model="form.feecod_shipper" type="number" name="feecod_shipper" />
								</div>
								<div class="col-sm-6 form-group">
									<label class="control-label">PPN COD Shipper</label>
									<input id="ppncod_shipper" class="form-control" v-model="form.ppncod_shipper" type="number" name="ppncod_shipper" />
								</div>
								<div class="col-sm-6 form-group">
									<label class="control-label">Insurance Fee</label>
									<input id="insurance_fee" class="form-control" v-model="form.insurance_fee" type="number" name="insurance_fee" />
								</div>
								<div class="col-sm-6 form-group">
								<label class="control-label">Fee COD Cashback</label>
								<input class="form-control" v-model="form.feecod_cashback" type="number"	min="0" step="0.1" placeholder="Bisa desimal" name="feecod_cashback" />
								</div>
								<div class="col-sm-6 form-group">
									<label class="control-label">Insurance Percent</label>
									<input class="form-control" v-model="form.insurance_percent" type="number" min="0" step="0.01" placeholder="Bisa desimal" name="insurance_percent" />
								</div>
								<div class="col-sm-6 form-group">
									<label class="control-label">Insurance Min</label>
									<input id="insurance_min" class="form-control" v-model="form.insurance_min" type="number" name="insurance_min" />
								</div>
								<div class="col-sm-6 form-group">
									<label class="control-label">PPN Ongkir Include</label>
									<input id="ppn_ongkir_inc" class="form-control" v-model="form.ppn_ongkir_inc" type="number" name="ppn_ongkir_inc" />
								</div>
								<div class="col-sm-6 form-group">
									<label class="control-label">Code</label>
									<input id="code" class="form-control" v-model="form.code" type="text" name="code" />
								</div>
								<div class="col-sm-6 form-group">
									<label class="control-label">Note</label>
									<input type="hidden" name="row_id" value="" />
									<input id="note" class="form-control" v-model="form.note" type="text" name="note" />
								</div>
								<div class="col-sm-6 form-group">
									<label class="control-label">Volume Price</label>
									<input id="volume_price" class="form-control" v-model="form.volume_price" type="number" name="volume_price" />
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label"> </label>
									<input id="active" v-model="form.active" type="checkbox" name="active" />
									<label for="active">Active</label>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-primary">
								Save changes
							</button>
							<button type="button" class="btn btn-secondary" data-dismiss="modal">
								Close
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</section>
	<!-- /.content -->
</template>
<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import moment from "moment";
import $ from "jquery";

export default {
	name: "dataServiceKurir",
	data() {
		return {
			errors: [],
			method: "",
			roles: "",
			tabmenu: 'service',
			downloading: false,
			groups: [],
			formTitle: "Tambah Service Kurir",
			form: {
				service_code: "",
				courier_id: [],
				percent_cashback: "",
				feecod_shipper: "",
				ppncod_shipper: "",
				insurance_fee: "",
				feecod_cashback: "",
				insurance_percent: "",
				insurance_min: "",
				ppn_ongkir_inc: "",
				code: "",
				note: "",
				volume_price: "",
				active: []
			},
			emptyLogs: false
		};
	},
	created: function () {
		this.roles = this.$route.meta.roles;
		console.log("load initial data", this.$route);

		//get data courier for dropdown select
		authFetch("/shipper/kurir")
		.then((res) => {
			if (res.status === 201) {
			} else if (res.status === 400) {
			}
			return res.json();
		})
		.then((js) => {
			this.groups = js.data;
		});
	},
	methods: {
		changeMenu(tipe) {
			$('.loading-overlay').addClass('show');
			if (this.tabmenu == tipe) {
				$('.loading-overlay').addClass('hide');
				return false;
			} else if (tipe == 'service') {
				this.loadService();
			} else if (tipe == 'logs') {
				this.loadLogs();
			} else {
				this.tabmenu = tipe;
			}
		},
		getImage(file) {
			return require(`../../assets/img/${file}.svg`); // the module request
		},
		getDate(time, hour = '') {
			return moment(time).format('DD/MM/YYYY ' + hour);
		},
		loadService: function (ev) {
			if (this.tableService) {
				this.tableService.api().ajax.reload(null, false);
			} else {
				const e = this.$refs;
				var self = this;
				this.tableService = createTable(e.tablecourierservice, {
					title: "Data Service Kurir",
					roles: this.$route.params.roles,
					ajax: "/master/courier/service",
					processing: true,
					filterBy: [0, 1],
					frame: true,
					responsive: true,
					scrollX: true,
					language: {
						processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
					columns: [
						{ data: "service_code" },
						{ data: "nama" },
						{ data: "percent_cashback" },
						{ data: "note" },
					],
					rowCallback: function (row, data) {
						if (data.percent_cashback) {
							$("td:eq(2)", row).html("<h6>" + data.percent_cashback + "%</h6>");
						}
					},
					buttonClick: (evt) => {
						if (evt.role == "create") {
							self.form = {};
							self.method = "POST";
							self.errors = [];
							self.formTitle = "Tambah Service Kurir";
							$(e.formDialog).modal("show");
						} else if (evt.role == "update" && evt.data) {
							self.form = evt.data;
							console.log(evt.data);
							self.form.active = evt.data.active == 0 ? false : true; //operator d
							self.method = "PUT";
							self.errors = [];
							self.formTitle = "Edit Service Kurir";
							$(e.formDialog).modal("show");
						} else if (evt.role == "delete" && evt.data) {
							console.log(evt);
							Swal.fire({
								title: "Hapus data?",
								icon: "question",
								denyButtonText: '<i class="fa fa-times"></i> Hapus',
								showCancelButton: true,
								showDenyButton: true,
								showConfirmButton: false,
							}).then((result) => {
								if (result.isDenied) {
									authFetch("/master/courier/service/" + evt.data.id, {
										method: "DELETE",
										body: "id=" + evt.data.id,
									})
									.then((res) => {
										return res.json();
									})
									.then((js) => {
										self.loadService();
									});
								}
							});
						}
					},
					initComplete: function () {
						$('.loading-overlay').removeClass('show');
					},
				});
			}

			this.tabmenu = 'service';
			this.processing = false;
			$('.loading-overlay').removeClass('show');
		},
		loadLogs: function() {
			if (this.tableLogs) {
				this.loadService();
				this.tabmenu = 'logs';
				$('.loading-overlay').removeClass('show');
			} else {
				const e = this.$refs;
				const self = this;

				this.tableLogs = createTable(e.tablelogs, {
					title: "Riwayat Perubahan",
					ajax: "/master/courier/history_service",
					frame: true,
					lengthChange: true,
					filter: false,
					filterBy: [],
					stateSave: true,
					processing: true,
					autoWidth: false,
					language: {
						processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'
					},
					// paramData: function (d) {
						// var filter = JSON.parse(JSON.stringify(self.filter_log));
						// for (const key in filter) {
						// 	d[key] = filter[key] === false ? 0 : filter[key];
						// }
					// },
					"order": [
						[2, 'desc']
					],
					columns: [
						{
							data: 'nama',
							render: function (data, type, row, meta) {
								return data + '\u00A0\u2014\u00A0' + row.service_code
							}
						},
						{
							data: 'history',
							render: function (data, type, row, meta) {
								var col = '';
								for (let x of Object.keys(JSON.parse(data))) {
									// col += x == 'tbl' ? '' : x[0].toUpperCase() + x.slice(1) + '<br>';
									/*
									"Kata Sandi":{"tbl":"users",%"password_hash":%
									*/
									col += x == 'tbl' ? '' : x + '<br>';
								}
								return col.substring(0, col.length - 4);
							}
						},
						{
							data: 'created_on',
							render: function (data, type, row, meta) {
								return self.getDate(data, '- HH:mm');
							}
						},
						{ data: 'display_name' },
						{
							data: 'history',
							render: function (data, type, row, meta) {
								var col = '';
								var data = JSON.parse(data);
								for (let x of Object.keys(data)) {
									col += x == 'tbl' ? '' : data[x][0] + '<br>';
								}
								return col.substring(0, col.length - 4);
							},
							sortable: false
						},
						{
							data: 'history',
							render: function (data, type, row, meta) {
								var col = '';
								var data = JSON.parse(data);
								for (let x of Object.keys(data)) {
									col += x == 'tbl' ? '' : data[x][1] + '<br>';
								}
								return col.substring(0, col.length - 4);
							},
							sortable: false
						},
					],
					drawCallback: function (row, data) {
						self.emptyLogs = row._iRecordsTotal > 0;
					},
					initComplete: function () {
						self.tabmenu = 'logs';
						$('.loading-overlay').removeClass('show');
					}
				});
			}
		},
		handleClick(e) {
			if (e.target.matches(".link-role")) {
				this.$router.push({ path: "/permit/" + e.target.dataset.id });
				return false;
			}
		},
		submitForm: function (ev) {
			const e = this.$refs;
			var data = Object.keys(this.form)
				.map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key]))
				.join("&");
			var urlSubmit = "/master/courier/service";
			if (this.method == "PUT")
				urlSubmit = "/master/courier/service/" + this.form.id;

			authFetch(urlSubmit, {
				method: this.method,
				body: data,
			})
			.then((res) => {
				if (res.status === 201) {
				} else if (res.status === 400) {
				}
				return res.json();
			})
			.then((js) => {
				this.errors = [];
				if (!js.success) {
					console.log(js.details);

					for (var key in js.details) {
						if (js.details.hasOwnProperty(key)) {
							this.errors.push(js.details[key]);
						}
					}

					return;
				}
				this.loadService();
				$(e.formDialog).modal("hide");
			});

			ev.preventDefault();
		},
		downloadXlsx: function (e) {
			this.downloading = true;
			authFetch("/master/courier/data_service", {
				method: "GET",
			})
			.then((response) => {
				if (response.status === 201) {
				} else if (response.status === 400) {
				} else if (response.status === 500){
					Swal.fire("Timeout!", ``, "error");
					return false;
				}
				return response.blob();
			})
			.then((blob) => {
				setTimeout(() => {
					this.downloading = false;
				}, 1000);
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement("a");
				a.href = url;
				a.download = "master_service_courier.xlsx";
				document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
				a.click();
				a.remove(); //afterwards we remove the element again
			});
		},
	},
	mounted() {
		this.loadService();
	},
};
</script>