<template>
	<section class="content">
		<div class="card">
			<div class="overlay" v-if="loadingContent"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
			<div class="card-body">
				<div class="row">
					<div class="col-12 col-sm-auto mb-3" :class="{ 'mr-sm-0' : false }">
						<div class="input-group">
							<input type="text" ref="daterange" class="form-control border-right-0" />
							<div class="input-group-append">
								<div class="input-group-text rounded-right d-flex align-items-center border-left-0 pl-1 bg-white">
									<i class="fi fi-rr-calendar d-flex"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="col-auto ml-auto mb-3" v-if="false">
						<div class="btn-group" ref="toolbar">
							<button type="button" data-action="read" class="btn btn-outline-primary border-right-0">
								<i class="fi fi-rr-refresh d-flex"></i>
							</button>
							<button type="button" data-action="update" class="btn btn-outline-primary d-flex align-items-center" :disabled="updating">
								<i class="fi fi-rr-edit d-flex align-items-center mr-2"></i> Ubah Reward
							</button>
							<button type="button" data-action="export-xlsx" class="btn btn-outline-primary d-flex align-items-center" :disabled="downloading" v-on:click="downloadXlsx" v-if="false">
								<i class="fi fi-rr-file-download d-flex align-items-center"></i> Download
							</button>
						</div>
					</div>
				</div>

				<table class="table table-hover w-100" ref="tableuser" id="tablshipper">
					<thead>
						<tr>
							<th>Shipper ID</th>
							<th>Nama</th>
							<th>Tanggal Bergabung</th>
							<th>Shipper</th>
							<th>Reward COD</th>
							<th>Reward Ongkir</th>
							<th>Status</th>
							<th>Aksi</th>
						</tr>
					</thead>
					<tbody @click="handleClick">
					</tbody>
				</table>

				<div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
					<div class="modal-dialog" role="document">
						<div class="modal-content">
							<form role="form" @submit="submitForm">
								<div class="modal-header">
									<h5 class="modal-title">{{ formTitle }}</h5>
									<button type="button" class="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div class="modal-body">
									<p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
										<b>Please correct the following error(s):</b>
										<ul>
											<li v-for="error in errors" v-bind:key="error">{{ error }}</li>
										</ul>
										<button type="button" class="close" data-dismiss="alert" aria-label="Close">
											<span aria-hidden="true">&times;</span>
										</button>
									</p>
									<div class="row">
										<div class="col-md-6 form-group" v-if="true">
											<label class="control-label">Reward COD</label>
											<input type="hidden" name="row_id" value="" />
											<input id="reward_cod" class="form-control" v-model="form.reward_cod" type="number" required="required" name="reward_cod" min="0" autocomplete="off" />
										</div>
										<div class="col-md-6 form-group" v-if="true">
											<label class="control-label">Reward Ongkir</label>
											<input type="hidden" name="row_id" value="" />
											<input id="reward_ongkir" class="form-control" v-model="form.reward_ongkir" type="number" required="required" name="reward_ongkir" min="0" autocomplete="off" />
										</div>
										<div class="col-md-6 form-group">
											<div class="icheck-material-orange w-100">
												<input type="checkbox" id="active" v-model="form.reward_status" />
												<label for="active" class="form-check-label w-100 font-weight-bold">{{ form.reward_status ? '' : 'Not' }} Active</label>
											</div>
										</div>
									</div>
								</div>
								<div class="modal-footer">
									<button type="submit" class="btn btn-primary" :disabled="loading">Save changes</button>
									<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div class="modal" tabindex="-1" role="dialog" ref="affiliateList" data-backdrop="static">
					<div class="modal-dialog d-flex align-items-center m-auto" role="document" style="height: 100vh;width: 480px;">
						<div class="modal-content">
							<div class="modal-body">
								<div class="d-flex justify-content-between">
									<div class="h5 font-weight-bold">Daftar Teman</div>
									<button class="btn btn-link m-0 p-0 text-dark" data-dismiss="modal">
										<i class="fi fi-rr-cross"></i>
									</button>
								</div>
								<div class="row mt-2">
									<ul class="list-group w-100">
										<li class="list-group-item border-left-0 border-bottom-0 border-right-0 pl-2" v-if="!referralList.length">
											<div class="row">
												<div class="col col-sm col-md"></div>
												<div class="col-8 col-sm-6 col-md-5">
													<img :src="getImage('afiliate-null')" class="w-100 mb-3" />
												</div>
												<div class="col col-sm col-md"></div>
												<div class="col-12 text-center">
													<div class="h5">Belum Ajak Teman</div>
													<p>
														Shipper belum pernah ajak teman menggunakan kode afiliasi.
													</p>
												</div>
											</div>
										</li>
										<li class="list-group-item border-left-0 border-bottom-0 border-right-0 pl-2" v-for="affiliated in referralList">
											<div class="row">
												<div class="col-auto"><img :src="'https://ui-avatars.com/api/?name=' + affiliated.nama + '&background=random&color=fff&size=32&length=1&rounded=true&bold-true'" /></div>
												<div class="col">
													<div class="font-weight-bold">{{ affiliated.nama }}</div>
													<small class="text-grey">Bergabung pada {{ shortDate(affiliated.created_on) }}</small>
												</div>
											</div>
										</li>
									</ul>
								</div>
							</div>
							<div class="modal-footer" v-if="false"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth, formatCurrency,openChat } from "@/libs/hxcore";
import $ from "jquery";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "icheck-material";
import moment from "moment";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import Swal from "sweetalert2";

export default {
	name: "Shipper",
	data() {
		return {
			errors: [],
			method: "",
			roles: "",
			filter: {},
			dt1: moment().startOf ("month"),
			dt2: moment(),
			loading: false,
			downloading: false,
			loadingContent: false,
			formTitle: "Tambah Shipper",
			form: {
				reward_cod: 0,
				reward_ongkir: 0,
				reward_status: "",
			},
			referralList: []
		};
	},
	computed: {
		kurirLogo() {
			const fileName = "jne"; //logo.toLowerCase()

			return require(`../assets/img/${fileName}.png`); // the module request
		},
	},
	components: {
		vSelect,
	},
	watch:{
		$route (to, from) {
			if (to.path === "/shipper/all") {
				sessionStorage.filterData='';
				sessionStorage.filterStatus = '';
				this.filter = {};
				this.table.api().ajax.reload();
			}
		}
	},
	created: function () {
		this.roles = this.$route.meta.roles;
		this.userInfo = auth.user();

		if (this.$route.params.filter) {
			this.filter = this.$route.params.filter;
		}

		if (this.filter.dt1 && this.filter.dt2) {
			this.dt1 = moment(this.filter.dt1);
			this.dt2 = moment(this.filter.dt2);
		}
		this.dateStart = this.dt1.format('YYYY-MM-DD');
		this.dateEnd = this.dt2.format('YYYY-MM-DD');
		if (this.filter.gid) {
			this.groupId = this.filter.gid;
			this.dateStart = '';
			this.dateEnd = '';
		}
		if (Object.keys(this.filter).length<1) {
			if (sessionStorage.filterData)this.filter = JSON.parse(sessionStorage.filterData);
		} else {
			sessionStorage.filterData = JSON.stringify(this.filter);
			sessionStorage.filterStatus = 1;
		}
		this.filter.datatype = this.data_type;
	},
	methods: {
		getImage(file) {
			return require(`../assets/img/${file}.svg`);
		},
		shortDate: function (date) {
			return moment(date).format('DD/MM/YYYY');
		},
		handleClick(e) {
			if (e.target.matches(".link-afiliasi")) {
				let route = this.$router.resolve({
					path: "/afiliasi-shipper-detail/" + e.target.dataset.to
				});
				window.open(route.href,"_blank");
			} else if (e.target.matches(".show-afiliasi")) {
				this.loadingContent = true;
				authFetch("/users/profile/affiliated/" + e.target.dataset.to)
					.then((res) => {
						if (res.status === 201) {} else if (res.status === 400) {}
						this.loadingContent = false;
						return res.json();
					})
					.then((js) => {
						this.referralList = js.data;
						$(this.$refs.affiliateList).modal("show");
						this.loadingContent = false;
					});
			} else if (e.target.matches(".edit-afiliasi")) {
				var nRow = $(e.target).closest("tr").get(0);
				var iRow = this.table.fnGetPosition(nRow);
				var aData = this.table.fnGetData(iRow);

				this.form.id = aData.id;
				this.form.reward_cod = aData.reward_cod ? aData.reward_cod : 0;
				this.form.reward_ongkir = aData.reward_ongkir ? aData.reward_ongkir : 0;
				if (aData.reward_status == 0 || !aData.reward_status) {
					this.form.reward_status = false;
				} else {
					this.form.reward_status = true;
				}
				this.method = "PUT";
				this.errors = [];
				this.formTitle = "Edit Shipper";
				$(this.$refs.formDialog).modal("show");
			}
		},
		onDate() {
			this.table.api().ajax.reload();
		},
		downloadXlsx: function (e) {
			this.downloading = true;
			var table = $('#tablshipper').DataTable();
			var length = table.page.info().recordsTotal;
			
			if (length > 20000) {
				Swal.fire("Proses gagal", `Maksimal data AWB sebanyak kurang lebih 20.000 data.`, "error");
				this.downloading = false;
				return false;
			}
			
			var filter = JSON.parse(JSON.stringify(this.filter));
				filter.q = $("input[name=q]").val();
				filter.category = $("select[name=category]").val();

			console.log(filter);
			var data = Object.keys(filter)
				.map(
					(key) =>
					encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
				)
				.join("&");
			console.log(data)
			this.loadingContent = true;

			authFetch("/report/shipper/excell", {
				method: "POST",
				body: data,
			})
				.then((response) => {
					if (response.status === 201) {
					} else if (response.status === 400) {
					} else if (response.status === 500) {
						Swal.fire("Timeout!", ``, "error");
						this.downloading = false;
						this.msg = '';
						return false;
					}

					return response.blob();
				})
				.then((blob) => {
					setTimeout(() => {
						this.downloading = false;
					}, 1000);
					this.loadingContent = false;
					var url = window.URL.createObjectURL(blob);
					var a = document.createElement("a");
					a.href = url;
					a.download = "data.xlsx";
					document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
					a.click();
					a.remove(); //afterwards we remove the element again
				});
		},
		submitForm: function (ev) {
			const e = this.$refs;
			this.loading = true;
			var allData = this.form;
			var urlSubmit = "/shipper/affiliasi/affiliator" + (this.method == "PUT" ? "/" + this.form.id : "");
			authFetch(urlSubmit, {
				method: this.method,
				body: JSON.stringify(allData),
			})
				.then((res) => {
					if (res.status === 201) {} else if (res.status === 400) {}
					return res.json();
				})
				.then((js) => {
					this.loading = false;
					this.errors = [];
					if (!js.success) {
						console.log(js.details);
						for (var key in js.details) {
							if (js.details.hasOwnProperty(key)) {
								this.errors.push(js.details[key]);
							}
						}
						return;
					}
					this.table.api().ajax.reload(null, false);
					$(e.formDialog).modal("hide");
				});

			ev.preventDefault();
		},
	},
	mounted() {
		const e = this.$refs;
		var self = this;
		new Daterangepicker(
			this.$refs.daterange,
			{
				startDate: !this.dt1 ? moment().day(-31) : this.dt1,
				endDate: !this.dt2 ? moment() : this.dt2,
				locale: {
					format: 'DD/MM/YYYY'
				},
			},
			function (d1, d2) {
				self.dateStart = d1.format("YYYY-MM-DD");
				self.dateEnd = d2.format("YYYY-MM-DD");
				self.filter.dt1 = self.dateStart;
				self.filter.dt2 = self.dateEnd;
				self.onDate();
			}
		);

		this.table = createTable(e.tableuser, {
			title: "",
			roles: this.$route.params.roles,
			ajax: "/shipper/affiliasi/affiliator",
			frame: false,
			toolbarEl: e.toolbar,
			scrollX: true,
			processing: true,
			autoWidth:false,
			language: {
				processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
			paramData: function (d) {
				var filter = JSON.parse(JSON.stringify(self.filter));
				d.dt1 = self.dateStart;
				d.dt2 = self.dateEnd;
				var filter = JSON.parse(JSON.stringify(self.filter));
				for (const key in filter) {
						d[key] = filter[key] === false ? 0 : filter[key];
				}
			},
			columns: [
				{ data: "shipper_code" },
				{
					data: "nama",
					render: function (data, type, row, meta) {
						return (
							`<a class="link link-afiliasi text-info" style="cursor:pointer" data-to="${row.id}">${data}</a>`
						);
					}
				},
				{
					data: "created_on",
					render: function (data, type, row, meta) {
						return moment(row.created_on).format("DD/MM/YYYY HH:mm");
					}
				},
				{
					data: "followers",
					render: function (data, type, row, meta) {
						return (
							`<a class="link show-afiliasi text-info" style="cursor:pointer" data-to="${row.id}">${data}</a>`
						);
					}
				},
				{
					data: "reward_cod",
					render: function (data, type, row, meta) {
						var diterima = data ? data : 0;
						return `${diterima}%`;
					}
				},
				{
					data: "reward_ongkir",
					render: function (data, type, row, meta) {
						var estimasi = data ? data : 0;
						return `${estimasi}%`;
					}
				},
				{
					data: "reward_status",
					render: function (data, type, row, meta) {
						var diterima = ""
						if (data == 1) {
							diterima = '<span class="badge badge-success">On</span>'
						} else {
							diterima = '<span class="badge badge-danger">Off</span>'
						}
						return `${diterima}`;
					}
				},
				{
					data: null,
					sortable: false,
					render: function (data, type, row, meta) {
						return (
							`<button type="button" class="btn btn-sm btn-outline-primary edit-afiliasi" data-to="${row.id}"><i class="fi fi-rr-edit edit-afiliasi" data-to="${row.id}"></i></button>`
						);
					}
				}
			],
			filterBy: [0,1],
			rowCallback: function (row, data) {
			},
			buttonClick: (evt) => {
				if (evt.role == "create") {
					self.form = {};
					self.method = "POST";
					self.errors = [];
					self.formTitle = "Edit Reward";
					$(e.formDialog).modal("show");
				} else if (evt.role == "update" && evt.data) {
					self.form.id = evt.data.id;
					self.form.reward_cod = evt.data.reward_cod;
					self.form.reward_ongkir = evt.data.reward_ongkir;
					if (evt.data.reward_status == 0 || !evt.data.reward_status) {
						self.form.reward_status = false;
					} else {
						self.form.reward_status = true;
					}
					self.method = "PUT";
					self.errors = [];
					self.formTitle = "Edit Shipper";
					$(e.formDialog).modal("show");
				} else if (evt.role == "delete" && evt.data) {
					self.form = evt.data;
					authFetch("/shipper/" + evt.data.id, {
						method: "DELETE",
						body: "id=" + evt.data.id,
					})
						.then((res) => {
							return res.json();
						})
						.then((js) => {
							this.table.api().ajax.reload();
						});
				}
			},
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
		});
	},
};
</script>
<style type="text/css">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
</style>