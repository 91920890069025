<template>
  <section class="content">
    <div class="container-fluid">
      <!-- <div class="card" v-if="isAdmin"> -->
      <div class="card" v-if="false">
        <div class="card-body">
          <div class="row" >
            <div class="col-sm-8">
              <div class="text-secondary">
                <!-- <h2 class="float-left mr-2"><i class="fa fa-gift"></i></h2> -->
                <div class="mb-3">
                  <h4 class="m-0" v-if="false">
                    Halo <strong>{{ username }} !</strong>
                  </h4>
                  <h6 class="m-0" v-if="false" style="margin-top: 8px !important;height:20px;">
                    Para Shipper telah mengirim {{ dataStatus.total_kiriman }} barang bersama BOSCOD
                  </h6>
                </div>
              </div>
            </div>
            <div class="col-sm-4 row" >
              <div class="col-sm-4" v-if="false">
                <div class="info-box vertical-center box-border row rasio" style="min-height: 50px; margin-right: 8px;">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-8" style="padding: 0px;display: flex;">
                    <i class="far fa-check-circle fa-2x" style="color: lawngreen;"></i>
                    <h6 class="info-text" style="align-self: flex-end;margin-left: 4px;">Rasio Sukses</h6>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-4" style="padding: 0px;text-align: right; align-self: center;">
                    <span style="font-size: initial; font-weight: 600; text-align: right;align-self: flex-end;">{{ dataStatus.rasio_sukses }}%</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-4" v-if="false">
                <div class="info-box vertical-center box-border row rasio" style="min-height: 50px; margin-right: 8px;">
                  <div class="col-12 col-sm-8" style="padding: 0px;display: flex;">
                    <i class="fas fa-undo-alt fa-2x" style="color: red;"></i>
                    <h6 class="info-text" style="align-self: flex-end;margin-left: 4px;">Rasio Retur</h6>
                  </div>
                  <div class="col-12 col-sm-4" style="padding: 0px;text-align: right; align-self: center;">
                    <span style="font-size: initial; font-weight: 600; text-align: right;">{{ dataStatus.rasio_retur }}%</span>
                  </div>
                </div>
              </div>
              <div class="col-12 "  >
                <div class="input-group vertical-center">
                    <input
                    type="text"
                    ref="daterange"
                    class="form-control"
                    style="border-right: 0"
                    />
                    <div class="input-group-append">
                    <div class="input-group-text" style="background-color: #fff">
                      <span
                      v-if="loading"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      ></span>
                      <span v-if="isReady"><span class="fa fa-calendar"></span></span>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-lg-12">
			<div class="callout callout-info">
				<div class="row">
          <div class="col-md-3">
                    <div class="info-box vertical-center box-border row rasio" style="min-height: 50px; margin-right: 8px;">
				                  <div class="col-12 col-sm-8" style="padding: 0px;display: flex;">
				                    <i class="fas fa-box fa-2x" style="color: cornflowerblue;"></i>
				                    <h6 class="info-text" style="align-self: flex-end; margin-left: 4px;">Total Kiriman</h6>
				                  </div>
				                  <div class="col-12 col-sm-4" style="padding: 0px;text-align: right;align-self: center;">
				                    <span style="font-size: initial; font-weight: 600; text-align: right;">{{ dataReturn.total_kiriman }}</span>
				                  </div>
				                </div>
          </div>
          <div class="col-md-3">
                    <div class="info-box vertical-center box-border row rasio" style="min-height: 50px; margin-right: 8px;">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-8" style="padding: 0px;display: flex;">
                    <i class="far fa-check-circle fa-2x" style="color: lawngreen;"></i>
                    <h6 class="info-text" style="align-self: flex-end;margin-left: 4px;">Rasio Sukses</h6>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-4" style="padding: 0px;text-align: right; align-self: center;">
                    <span style="font-size: initial; font-weight: 600; text-align: right;align-self: flex-end;">{{ dataReturn.rasio_success }}%</span>
                  </div>
                </div>
          </div>
          <div class="col-md-3">
                    <div class="info-box vertical-center box-border row rasio" style="min-height: 50px; margin-right: 8px;">
                  <div class="col-12 col-sm-8" style="padding: 0px;display: flex;">
                    <i class="fas fa-undo-alt fa-2x" style="color: red;"></i>
                    <h6 class="info-text" style="align-self: flex-end;margin-left: 4px;">Rasio Retur</h6>
                  </div>
                  <div class="col-12 col-sm-4" style="padding: 0px;text-align: right; align-self: center;">
                    <span style="font-size: initial; font-weight: 600; text-align: right;">{{ dataReturn.rasio_retur }}%</span>
                  </div>
                </div>
          </div>
          <div class="col-md-3">
            <div class="row">
              <div class="col-md-12 mt-2">
                    <div class="input-group vertical-center">
                    <input
                    type="text"
                    ref="daterange"
                    class="form-control"
                    style="border-right: 0"
                    />
                    <div class="input-group-append">
                    <div class="input-group-text" style="background-color: #fff">
                      <span
                      v-if="loading"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      ></span>
                      <span v-if="isReady"><span class="fa fa-calendar"></span></span>
                    </div>
                    </div>
                </div>
              </div>
              <div class="col-md-12 mt-2 ">
                  <select class="form-control w-100" @change="kurirChange($event)">
                    <option value="#" disabled selected>Pilih kurir</option>
                    <option v-for="allcourier in courier" v-bind:key="allcourier.id" v-bind:value="allcourier.id">
                            {{ allcourier.prefix }}
                    </option>
                    <option value="0">Semua</option>
                  </select>
              </div>
            </div>
          </div>
				</div>
            </div>
        </div>
        <div class="col-lg-3">
			
        </div>
      </div>
      <div class="row mt-3">
        <!--PERSIAPAN-->
        <div class="info-box row" style="width: 100%;border-radius: 16px;padding: 0px;margin: .5rem;">
          <div class="col-12 col-sm-2 cat">
            <div class="info-box-content text-light vertical-center" style="padding: 5px;">
              <h5 class="info-box-number"><i class="fas fa-box"></i></h5>
              <h5 class="info-box-text">Persiapan</h5>
            </div>
          </div>
          <div class="col-12 col-sm-10 row margin-0">
            <!-- <div class="col-12 col-sm-6 col-md-3">
              <router-link
                :to="{
                  name: 'Riwayat Admin',
                  params: {filter: { draft: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                }"
              >
                <div class="info-box mb-3 vertical-center box-border row">
                  <div class="row" style="padding-left: 10px;">
                    <i class="hxf hxf-draft" style="font-size: 24px;"></i><h5 class="info-text">Draft</h5>
                  </div>
                  <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                    <div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading }">
                      <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.draft }}</h5>
                    </div>
                  </div>
                </div>
              </router-link>
            </div> -->
            <div class="col-12 col-sm-6 col-md-3">
              <router-link
                :to="{
                  name: 'Riwayat Admin',
                  params: {filter: { rpick: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                }"
              >
                <div class="info-box mb-3 vertical-center box-border row">
                  <div class="row" style="padding-left: 10px;">
                    <i class="hxf hxf-request-pickup" style="font-size: 24px;"></i><h5 class="info-text">Request Pickup</h5>
                  </div>
                  <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                    <div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading }">
                      <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.reqPickup }}</h5>
                    </div>
                  </div>
                </div>
              </router-link>
              <!-- /.info-box -->
            </div>
            
            <div class="col-12 col-sm-6 col-md-3">
              <router-link
                :to="{
                  name: 'Riwayat Admin',
                  params: {filter: { canc: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                }"
              >
                <div class="info-box mb-3 vertical-center box-border row">
                  <div class="row" style="padding-left: 10px;">
                    <i class="fa fa-ban text-primary" style="font-size: 24px;"></i><h5 class="info-text">Cancel Pesanan</h5>
                  </div>
                  <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                    <div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading }">
                      <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.cancel }}</h5>
                    </div>
                  </div>
                </div>
              </router-link>
              <!-- /.info-box -->
            </div>
          </div>
        </div>
        <!--PENGIRIMAN-->
        <div class="info-box row" style="width: 100%;border-radius: 16px;padding: 0px;margin: .5rem;">
          <div class="col-12 col-sm-2 cat">
            <div class="info-box-content text-light vertical-center" style="padding: 5px;">
              <h5 class="info-box-number"><i class="fas fa-car"></i></h5>
              <h5 class="info-box-text">Pengiriman</h5>
            </div>
          </div>
          <div class="col-12 col-sm-10 row margin-0">
            <div class="col-12 col-sm-6 col-md-3">
              <router-link
                :to="{
                  name: 'Riwayat Admin',
                  params: {filter: { delgo: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                }"
              >
                <div class="info-box mb-3 vertical-center box-border row">
                  <div class="row" style="padding-left: 10px;">
                    <i class="hxf hxf-delivery" style="font-size: 24px;"></i><h5 class="info-text">Pengiriman</h5>
                  </div>
                  <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                    <div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading }">
                      <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.delivery }}</h5>
                    </div>
                  </div>
                </div>
              </router-link>
              <!-- /.info-box -->
            </div>
            <div class="col-12 col-sm-6 col-md-3">
              <router-link
                :to="{
                  name: 'Riwayat Admin',
                  params: {filter: { deler: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                }"
              >
                <div class="info-box mb-3 vertical-center box-border row">
                  <div class="row" style="padding-left: 10px;">
                    <i class="fa fa-exclamation-triangle" style="font-size: 24px;"></i><h5 class="info-text">Problem</h5>
                  </div>
                  <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                    <div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading }">
                      <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.problem }}</h5>
                    </div>
                  </div>
                </div>
              </router-link>
              <!-- /.info-box -->
            </div>
            
            <div class="col-12 col-sm-6 col-md-3">
              <router-link
                :to="{
                  name: 'Riwayat Admin',
                  params: {filter: { delok: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                }"
              >
                <div class="info-box mb-3 vertical-center box-border row">
                  <div class="row" style="padding-left: 10px;">
                    <i class="hxf hxf-delivered" style="font-size: 24px;"></i><h5 class="info-text">Telah Diterima</h5>
                  </div>
                  <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                    <div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading }">
                      <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.delivered }}</h5>
                    </div>
                  </div>
                </div>
              </router-link>
              <!-- /.info-box -->
            </div>
            <div class="col-12 col-sm-6 col-md-3">
              <router-link
                :to="{
                  name: 'Riwayat Admin',
                  params: {filter: { retgo: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                }"
              >
                <div class="info-box mb-3 vertical-center box-border row">
                  <div class="row" style="padding-left: 10px;">
                    <i class="hxf hxf-returned" style="font-size: 24px;"></i><h5 class="info-text">Proses Retur</h5>
                  </div>
                  <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                    <div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading }">
                      <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.returngo }}</h5>
                    </div>
                  </div>
                </div>
              </router-link>
              <!-- /.info-box -->
            </div>
          </div>
        </div>
        <!--RETUR-->
        <div class="info-box row" style="width: 100%;border-radius: 16px;padding: 0px;margin: .5rem;">
          <div class="col-12 col-sm-2 cat">
            <div class="info-box-content text-light vertical-center" style="padding: 5px;">
              <h5 class="info-box-number"><i class="fas fa-undo-alt"></i></h5>
              <h5 class="info-box-text">Retur</h5>
            </div>
          </div>
          <div class="col-12 col-sm-10 row margin-0">
            <div class="col-12 col-sm-6 col-md-3">
              <router-link
                :to="{
                  name: 'Riwayat Admin',
                  params: {filter: { retok: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                }"
              >
                <div class="info-box mb-3 vertical-center box-border row">
                  <div class="row" style="padding-left: 10px;">
                    <i class="hxf hxf-returned" style="font-size: 24px;"></i><h5 class="info-text">Diserahkan</h5>
                  </div>
                  <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                    <div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading }">
                      <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.returned }}</h5>
                    </div>
                  </div>
                </div>
              </router-link>
              <!-- /.info-box -->
            </div>
            <div class="col-12 col-sm-6 col-md-3">
              <router-link
                :to="{
                  name: 'Riwayat Admin',
                  params: {filter: { retacc: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                }"
              >
                <div class="info-box mb-3 vertical-center box-border row">
                  <div class="row" style="padding-left: 10px;">
                    <i class="hxf hxf-returned" style="font-size: 24px;"></i><h5 class="info-text">Diterima</h5>
                  </div>
                  <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                    <div class="info-box-content text-dark" style="padding: 0px;" v-bind:class="{ 'skeleton-item': loading }">
                      <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.retReceived }}</h5>
                    </div>
                  </div>
                </div>
              </router-link>
              <!-- /.info-box -->
            </div>
          </div>
        </div>
      </div>
      <div id="_sectionNotMove">
          <div class="row mt-3">
            <!--TIDAK BERGERAK-->
            <div class="info-box row" style="width: 100%;border-radius: 16px;padding: 0px;margin: .5rem;">
              <div class="col-12 col-sm-2 cat">
                <div class="info-box-content text-light vertical-center" style="padding: 5px;">
                  <h5 class="info-box-number"><i class="far fa-stop-circle"></i></h5>
                  <h5 class="info-box-text">Tidak Bergerak</h5>
                </div>
              </div>
              <div class="col-12 col-sm-10 row margin-0">
                <div class="col-12 col-sm-6 col-md-3">
                  <router-link
                    :to="{
                      name: 'Riwayat Admin',
                      params: {filter: { nmove5: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                    }"
                  >
                    <div class="info-box mb-3 vertical-center box-border row">
                      <div class="row" style="padding-left: 10px;">
                        <i class="hxf hxf-nochange" style="font-kerning: 24px;"></i><h5 class="info-text">>5 Hari</h5>
                      </div>
                      <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                        <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading2 }" style="padding: 0px;">
                          <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ notMove.notMove5 }}</h5>
                        </div>
                      </div>
                    </div>
                  </router-link>
                  <!-- /.info-box -->
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <router-link
                    :to="{
                      name: 'Riwayat Admin',
                      params: {filter: { nmove10: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                    }"
                  >
                    <div class="info-box mb-3 vertical-center box-border row">
                      <div class="row" style="padding-left: 10px;">
                        <i class="hxf hxf-nochange" style="font-kerning: 24px;"></i><h5 class="info-text">>10 Hari</h5>
                      </div>
                      <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                        <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading2 }" style="padding: 0px;">
                          <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ notMove.notMove10 }}</h5>
                        </div>
                      </div>
                    </div>
                  </router-link>
                  <!-- /.info-box -->
                </div>
                
                <div class="col-12 col-sm-6 col-md-3">
                  <router-link
                    :to="{
                      name: 'Riwayat Admin',
                      params: {filter: { nmove14: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                    }"
                  >
                    <div class="info-box mb-3 vertical-center box-border row">
                      <div class="row" style="padding-left: 10px;">
                        <i class="hxf hxf-nochange text-primary"></i><h5 class="info-text">>14 hari</h5>
                      </div>
                      <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                        <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading2 }" style="padding: 0px;">
                          <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{notMove.notMove14}}</h5>
                        </div>
                      </div>
                    </div>
                  </router-link>
                  <!-- /.info-box -->
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <!-- <router-link
                    :to="{
                      name: 'Riwayat Admin',
                      params: {filter: { rpick: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                    }"
                  > -->
                    <div class="info-box mb-3 vertical-center box-border row" v-if="false">
                      <div class="row" style="padding-left: 10px;">
                        <i class="far fa-edit fa-2x"></i><h5 class="info-text">Claim Breach</h5>
                      </div>
                      <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                        <div class="info-box-content text-dark" style="padding: 0px;">
                          <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">0</h5>
                        </div>
                      </div>
                    </div>
                  <!-- </router-link> -->
                  <!-- /.info-box -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="_sectionTicket">
            <div class="row mt-3">
            <!--TICKET-->
            <div class="info-box row" style="width: 100%;border-radius: 16px;padding: 0px;margin: .5rem;">
              <div class="col-12 col-sm-2 cat">
                <div class="info-box-content text-light vertical-center" style="padding: 5px;">
                  <h5 class="info-box-number"><i class="far fa-stop-circle"></i></h5>
                  <h5 class="info-box-text">Ticket & Claim</h5>
                </div>
              </div>
              <div class="col-12 col-sm-10 row margin-0">
                <div class="col-12 col-sm-6 col-md-3">
                  <router-link
                    :to="{
                      name: 'List Ticket',
                      params: {filter: { open: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                    }"
                  >
                    <div class="info-box mb-3 vertical-center box-border row">
                      <div class="row" style="padding-left: 10px;">
                        <i class="fas fa-inbox text-primary" style="font-size: 24px;"></i><h5 class="info-text">Open</h5>
                      </div>
                      <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                        <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading3 }" style="padding: 0px;">
                          <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ allTicket.open }}</h5>
                        </div>
                      </div>
                    </div>
                  </router-link>
                  <!-- /.info-box -->
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <router-link
                    :to="{
                      name: 'List Ticket',
                      params: {filter: { closed: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                    }"
                  >
                    <div class="info-box mb-3 vertical-center box-border row">
                      <div class="row" style="padding-left: 10px;">
                        <i class="fas fa-check-double text-primary" style="font-size: 24px;"></i><h5 class="info-text">Closed</h5>
                      </div>
                      <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                        <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading3 }" style="padding: 0px;">
                          <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ allTicket.closed }}</h5>
                        </div>
                      </div>
                    </div>
                  </router-link>
                  <!-- /.info-box -->
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <router-link
                    :to="{
                      name: 'List Claim',
                      params: {filter: { retclm: 1, dt1: dateStart, dt2: dateEnd, courier_id: courier_id } },
                    }"
                  >
                    <div class="info-box mb-3 vertical-center box-border row">
                      <div class="row" style="padding-left: 10px;">
                        <i class="hxf hxf-rtk text-primary" style="font-size: 24px;"></i><h5 class="info-text">Claim</h5>
                      </div>
                      <div class="row" style="width: 100%; padding: 0px;margin-right: 0px;margin-left: 0px;">
                        <div class="info-box-content text-dark" v-bind:class="{ 'skeleton-item': loading3 }" style="padding: 0px;">
                          <h5 class="vertical-center text-higher" style="text-align: right;font-weight: 700;color: #495057;">{{ dataStatus.retClaim }}</h5>
                        </div>
                      </div>
                    </div>
                  </router-link>
                  <!-- /.info-box -->
                </div>
              </div>
            </div>
          </div>
        </div>
      <div id="_sectionChart" v-if="false">
        <div class="row mt-3">
          <div class="col-12">
            <div class="info-box">
              <div class="overlay" v-if="loading4"><i class="fas fa-4x fa-sync-alt fa-spin"></i></div>
              <canvas ref="myChart2"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Daterangepicker from "daterangepicker";
import $ from "jquery";
import Chart from "chart.js";
import { authFetch, auth, formatCurrency } from "@/libs/hxcore";
import moment from "moment";
import "daterangepicker/daterangepicker.css";
var myChart2 = null;
export default {
  name: "Home",
  data: function () {
    return {
      isAdmin: false,
      dateStart: moment().day(-5).format("YYYY-MM-DD"),
      dateEnd: moment().add(2, 'd').format("YYYY-MM-DD"),
      dataReturn: {},
      dataStatus: {},
      notMove: {},
      allTicket: {},
      dataFinance: {},
      dataCashback: {},
      courier: {},
      allcourier: {},
      courier_id: '',
      url: window.$apiUrl,
      dt: {
        start: "",
        end: "",
      },
      dataCourierPie: {
        type: "pie",
        data: [],
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: "Data Courier",
            },
          },
        },
      },
      dataCourierBar: {
        type: "bar",
        data: [],
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: "Data",
            },
          },
        },
      },
      infoBar: [],
      loading: false,
      loading2: false,
      loading3: false,
      loading4: false,
      loadedMove: false,
      loadedTicket: false,
      loadedChart: false,
    };
  },
  created: function () {
    var info = auth.user();
    if (info) {
      this.username = info.display_name;
      this.isAdmin = info.role_id < 2;
    }
    // this.loadAllStatus();
    this.loadStatusOrder();
    this.loadKurir();
    this.loadStatusReturn();
    this.loadNotMove();
    this.loadAllStatus();
    // this.loadNotMove = false;
    // this.loadStatusTicket = false;
    // this.loadStatusChart = false;
  },
  computed: {
    isReady: function () {
      return !this.loading;
    },
  },
  methods: {
    kurirChange(event) {
      let id = event.target.value;
      this.courier_id = id;
      this.loadStatusReturn();
      this.loadStatusOrder();
      this.loadedMove = false;
      this.loadedTicket = false;
      this.loadNotMove();
      this.loadAllStatus();
    },
    loadStatusReturn() {
      console.log(this.courier_id);
      var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id?this.courier_id:'' };
      this.loading = true;
      var search = new URLSearchParams(params);
      authFetch(`/dashboard/admin/tren_returKurir?${search}`).then((res) => {
          res.json().then((json) => {
          this.loading = false;
          if (json.success) {
            this.dataReturn = json.data;
          }
        });
      });
    },
    loadStatusOrder() {
    var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id?this.courier_id:'' };
    this.loading = true;
    var search = new URLSearchParams(params);
    authFetch(`/dashboard/admin?${search}`).then((res) => {
        res.json().then((json) => {
        this.loading = false;
        if (json.success) {
            this.dataStatus = json.status;
            this.dataStatus.rasio_sukses = json.status.rasio_sukses.toFixed(1);
            this.dataStatus.rasio_retur = json.status.rasio_retur.toFixed(2);
        }
        });
    });
    },
    loadKurir() {
    authFetch(`/dashboard/admin/kurir`).then((res) => {
        res.json().then((json) => {
        this.loading = false;
       // this.courier = json.data;
        if (json.success) {
            this.courier = json.data;
            
        console.log('kurir',courier);
        }
        });
    });
    },
    loadNotMove: function () {
        if (this.loadedMove == true) {
            return;
        } else {
            this.loadedMove = true;
            var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id?this.courier_id:'' };
            this.loading2 = true;
            var search = new URLSearchParams(params);
            authFetch(`/dashboard/admin/not_move?${search}`)
                .then((res) => {
                res.json().then((json) => {
                this.loading2 = false;
                if (json.success) {
                    this.notMove = json.status;
                }
                });
        });
        }
    },
    loadStatusTicket: function () {
        if (this.loadedTicket == true) {
          console.log('tes');
            return;
        } else {
            this.loadedTicket = true;
            var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id?this.courier_id:'' };
            this.loading3 = true;
            var search = new URLSearchParams(params);
            authFetch(`/dashboard/admin/all_ticket?${search}`).then((res) => {
              if (res.status === 201) {

              } else if (res.status === 400) {

              }
              this.loading3 = false;
              return res.json();
            }).then((js) => {
              this.allTicket = js;
            });
        }
    },
    loadStatusChart: function () {
      if (this.loadedChart == true) {
            return;
        } else {
            this.loadedChart = true;
            var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id?this.courier_id:'' };
            this.loading4 = true;
            var search = new URLSearchParams(params);
            authFetch(`/dashboard/admin/statistic_chart?${search}`).then((res) => {
                res.json().then((json) => {
                this.loading4 = false;
                if (json.success) {
                    this.dataCourierBar.data = json.status.barChart;
                    myChart2.update();
                }
                });
            });
        }
    },
    loadAllStatus() {
		if (this.loadedTicket == true) {
            return;
        } else {
			this.loadedTicket = true;
      var params = { dt1: this.dateStart, dt2: this.dateEnd, courier_id: this.courier_id?this.courier_id:'' };
			this.loading3 = true;
      var search = new URLSearchParams(params);
			authFetch(`/ticket/history/all_tickets?${search}`)
				.then(res => {
				if (res.status === 201) {
				} else if (res.status === 400) {
				}
				return res.json();
				})
				.then(js => {
				this.loading3 = false
				this.allTicket = js.data;
				$('.loading-overlay').removeClass('show');
				});
		}
    },
    formatMoney(val) {
      return formatCurrency(val);
    },
    // onReload() {
    //   var params = { dt1: this.dateStart, dt2: this.dateEnd };
    //   this.loading = true;
    //   var search = new URLSearchParams(params);
    //   authFetch(`/dashboard/admin?${search}`).then((res) => {
    //     res.json().then((json) => {
    //       this.loading = false;
    //       if (json.success) {
    //         this.dataCourierBar.data = json.barChart;

    //         myChart2.update();
    //         this.dataStatus = json.status;
    //       }
    //     });
    //     //loading(false);
    //   });
    // },
  },
  mounted() {
    let self = this;

    //daterange all
    new Daterangepicker(
      this.$refs.daterange,
      {
        startDate: moment().day(-5),
        endDate: moment().add(2, 'd'),
      },
      function (d1, d2) {
        self.dateStart = d1.format("YYYY-MM-DD");
        self.dateEnd = d2.format("YYYY-MM-DD");
        self.loadStatusReturn();
        self.loadStatusOrder();
        self.loadedMove = false;
        self.loadedTicket = false;
        self.loadNotMove();
        self.loadAllStatus();
        // self.loadedChart = false;
        // self.onReload();
      }
    );

    // var ctx = this.$refs.myChart.getContext("2d");
    // var ctx2 = this.$refs.myChart2.getContext("2d");

    // myChart = new Chart(ctx, this.dataCourierPie);
    // myChart2 = new Chart(ctx2, this.dataCourierBar);
    // window.addEventListener('scroll', function (event) {
    //     // const self = this;
    //     if ($('#_sectionTicket').length == 0) {
    //       console.log('tes');
    //         return;
    //     }
    //     var hT2 = $('#_sectionNotMove').offset().top,
    //         hH2 = $('#_sectionNotMove').outerHeight(),
    //         hT3 = $('#_sectionTicket').offset().top,
    //         hH3 = $('#_sectionTicket').outerHeight(),
    //         hT4 = $('#_sectionChart').offset().top,
    //         hH4 = $('#_sectionChart').outerHeight(),
    //         wH = $(window).height(),
    //         wS = $(this).scrollTop();
    //     console.log(hT2);
    //     // console.log(hT2, hH2, wH, wS);
    //     if (wS > (hT2+hH2-wH)){
    //       console.log('tes masuk');
    //         //load ticket
    //         // self.loadNotMove();
    //     } 
    //     if (wS > (hT3+hH3-wH)){
    //         //load chart
    //         // self.loadAllStatus();
    //     } 
    //     if (wS > (hT4+hH4-wH)){
    //         //load finance report
    //         // self.loadStatusChart();
    //     }
    // });
  },
};
</script>
<style>
  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    /*text-align: center;*/
  }

  .text-center-v {
    align-self: center;
  }

  .text-padding-12 {
    padding-top: 14px;
    overflow-wrap: anywhere;
  }

  @media (min-width: 300px) and (max-width: 1023px) {
    .text-padding-12 {
      padding-top: 0px;
      overflow-wrap: anywhere;
    }
  }

  .text-light {
    color: white;
  }
  .vertical-center {
    margin: 0;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .box-border {
    border: 1.5px #C4C4C4 solid;
  }
  .info-box .info-box-text {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 600;
  }
  .text-higher {
      font-size: large;
  }
  .info-text{
      color: initial;
      padding: 3px;
  }
  .cat {
    text-align: center;
    vertical-align: middle;
    background-color: rgb(41, 47, 65);
    border-bottom-left-radius: 16px;
    border-top-left-radius: 16px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    .cat {
      border-radius: 0px;
    }
    .margin-0 {
      margin-left: 0px;
      margin-right: 0px;
    }
    .rasio {
      margin-bottom: 12px;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .info-box .cat {
      border-radius: 0px;
    } 
    .margin-0 {
      margin-left: 0px;
      margin-right: 0px;
    }
    .rasio {
      margin-bottom: 12px;
    }
  }
  @media (max-width: 320px) {
    .info-box .cat {
      border-radius: 0px;
    } 
    .margin-0 {
      margin-left: 0px;
      margin-right: 0px;
    }
    .rasio {
      margin-bottom: 12px;
    }
  }
</style>