<template>
	<section class="content">
		<div class="form-control bos-alert-primary d-flex align-items-center h-100 mb-3">
			<div class="row">
				<div class="col-auto d-flex align-items-center">
					<i class="fi fi-rr-info d-flex align-items-center h5 mb-0"></i>
				</div>
				<div class="col d-flex align-items-center lh-1">
					<div class="text-md">
						Ini adalah halaman terakhir sebelum ajukan tiket batch. Periksa kembali dan pastikan data yang kamu isi sudah sesuai dan benar
					</div>
				</div>
			</div>
		</div>
		<div class="card card-primary">
			<div class="card-body">
				<div class="form-control bos-alert-danger d-flex align-items-center h-100 mb-3" v-if="errorNote">
					<div class="row">
						<div class="col-auto d-flex align-items-center">
							<i class="fi fi-rr-info d-flex align-items-center h5 mb-0"></i>
						</div>
						<div class="col d-flex align-items-center lh-1">
							<div class="text-md">
								Ada error karena AWB/Resi kamu bermasalah, silahkan hapus/edit untuk melanjutkan proses.
							</div>
						</div>
					</div>
				</div>
				<table class="table table-hover w-100" ref="ticketBatch">
					<thead>
						<tr>
							<th>
								<input type="checkbox" id="checkall" ref="checkall" name="checkall" value="1" @click="selectAll" />
							</th>
							<th>AWB/No. Resi</th>
							<th>Kategori</th>
							<th>Note</th>
							<th>Keterangan</th>
							<th>Aksi</th>
						</tr>
					</thead>
					<tbody @click="handleClick"></tbody>
				</table>
			</div>
			<div class="card-footer text-right mb-2">
				<button type="submit" id="submit" class="btn btn-primary" :disabled="errorNote || updating" @click="submitForm">
					<span v-if="updating" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
					Ajukan Tiket
				</button>
			</div>
		</div>
		<!-- Modal -->
		<div class="modal fade" id="updateTicketBatch" ref="modalUpdate" tabindex="-1" role="dialog" aria-labelledby="updateTicketBatchLabel" aria-hidden="true">
			<div class="modal-dialog modal-sm" role="document">
				<div class="modal-content">
					<div class="modal-header bg-light">
						<h5 class="modal-title font-weight-bold" id="updateTicketBatchLabel">Buat Tiket Batch</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body bg-white">
						<div class="form-group">
							<label class="font-weight-normal">AWB/Resi</label>
							<input type="text" name="refcode" v-model="form.refcode" placeholder="Masukkan AWB / No. Resi" class="form-control" :class="{ 'is-invalid': errors.refcode }" required />
						</div>
						<div class="form-group">
							<label class="font-weight-normal">Kategori Tiket</label>
							<v-select :options="categories" :reduce="(opt) => opt.code" name="category" v-model="form.category" @input="selectCategory" placeholder="Pilih kategori bantuan" required>
								<template #search="{attributes, events}">
									<input class="vs__search" v-bind="attributes" v-on="events" :class="{ 'is-invalid': errors.category }" />
								</template>
							</v-select>
						</div>
						<div class="form-group" v-if="form.category == 6">
							<label class="font-weight-normal">Kategori Lainnya</label>
							<input type="text" name="title" v-model="form.title" placeholder="Masukkan kategori lainnya" class="form-control" maxlength="100" :class="{ 'is-invalid': errors.title }" required />
						</div>
						<div class="form-group">
							<label class="font-weight-normal">Pesan</label>
							<textarea name="message" v-model="form.message" placeholder="Tuliskan keluhan kamu" minlength="15" class="form-control h-100" style="resize: none;" :class="{ 'is-invalid': errors.message }" required
								onkeydown="return (event.ctrlKey || event.altKey || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==57 && event.shiftKey==true) || (95<event.keyCode && event.keyCode<106) || (event.keyCode==8) || (event.keyCode==9) || (event.keyCode==48 && event.shiftKey==true) || (event.keyCode==190 && event.shiftKey==false) || (event.keyCode==188 && event.shiftKey==false) || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))">
							</textarea>
							<small class="text-danger" v-if="errors.message">Maksimal 400 karakter</small>
						</div>
					</div>
					<div class="modal-footer">
						<button type="reset" class="btn btn-outline-primary" data-dismiss="modal">Kembali</button>
						<button type="submit" class="btn btn-primary" :disabled="updating" @click="updateData">Simpan Data <span v-if="updating" class="spinner-border spinner-border-sm"></span></button>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import Swal from 'sweetalert2';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import $ from 'jquery';

export default {
	name: 'TiketBatch',
	data() {
		return {
			errorNote: false,
			categories: [],
			form: {
				refcode: null,
				category: null,
				title: null,
				message: null
			},
			errors: {
				refcode: false,
				category: false,
				title: false,
				message: false
			},
			updating: false
		}
	},
	methods: {
		selectAll: function (e) {
			var val = e.target.checked;
			var checkboxes = document.querySelectorAll('.select-request');

			for (var i = 0; i < checkboxes.length; i++) {
				checkboxes[i].checked = val;
			}

			if (val && checkboxes.length > 0) {
				this.selectedCount = checkboxes.length;
			} else {
				this.selectedCount = 0;
			}

			if (this.selectedCount > 0) {
				$(this.$refs.btnSelected).show();
			} else {
				$(this.$refs.btnSelected).hide();
			}
		},
		handleClick(e) {
            const evt = this.$refs;
			if (e.target.closest("i")) {
				if (e.target.dataset.action == "edit") {
					var nRow = $(e.target).closest("tr").get(0);
					var iRow = this.table.fnGetPosition(nRow);
					var aData = this.table.fnGetData(iRow);

					this.form = aData;
					if (this.form.category == 6) {
						this.form.category = '6';
					}
					this.form.nRow = nRow;
					$(evt.modalUpdate).modal("show");
				} else if (e.target.dataset.action == "remove") {
					var nRow = $(e.target).closest("tr").get(0);
					this.table.fnDeleteRow(nRow);
					this.errorNote = this.table.fnGetData().filter((el) => { return el.status == 0; }).length > 0;
				}
			}
		},
		getImage(file) {
			return require(`../assets/img/${file}.svg`);
		},
		selectCategory(v) {
			let cat = this.categories.filter(function (arr, i) {
				return arr.code == v;
			});

			if (cat[0].code != 6) {
				this.form.title = cat[0].label;
			} else {
				this.form.title = "";
			}
		},
		updateData() {
			this.updating = true;
			authFetch('/ticket/status?awb[]=' + this.form.refcode + '&title[]=' + this.form.title)
			.then((res) => {
				if (res.status === 201) {} else if (res.status === 400) {}
				return res.json();
			})
			.then((js) => {
				this.form.status = js.data.status[0];
				this.form.category = js.data.category[0];
				let nRow = this.form.nRow;
				var aData = this.form;

				this.table.fnUpdate(aData, nRow);
				this.errorNote = this.table.fnGetData().filter((el) => { return el.status == 0; }).length > 0;
				$("td .btn.edit", nRow).click();

				if (js.data.status[0] == 0) {
					$(nRow).css('background-color', '#F7EBEB');
				} else {
					$(nRow).css('background-color', '');
				}

				this.form = {
					refcode: null,
					category: null,
					title: null,
					message: null
				};

				this.errors = {
					refcode: false,
					category: false,
					title: false,
					message: false
				};

				$(this.$refs.modalUpdate).modal("hide");
				this.updating = false;
			});
		},
		submitForm() {
			this.updating = true;
			$('.loading-overlay').addClass('show');
			authFetch("/ticket/import", {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json"
				},
				body: JSON.stringify(this.table.fnGetData())
			})
			.then(res => {
				if (res.status === 201) {} else if (res.status === 400) {}
				return res.json();
			})
			.then(js => {
				if (js.status == 'success') {
					this.customSwal.fire({
						html: '<img src="' + this.getImage('ok') + '" class="swal2-img d-block mx-auto mb-3" /><div class="h5">' + js.message + '</div>',
						showConfirmButton: false,
						showCancelButton: true,
						cancelButtonText: 'Kembali'
					});
					this.$router.push({ path: '/settings/tickets/all' });
				} else if (js.status == 'error') {
					this.customSwal.fire({
						html: '<img src="' + this.getImage('overload') + '" class="swal2-img d-block mx-auto mb-3" /><div class="h5">' + js.message + '</div><div class="w-75 m-auto" style="height: 100px;overflow-y: scroll;"><ul class="text-left"><li>' + (js.details ? Object.keys(js.details).map(key => js.details[key]).join('</li><li>') : js.errors ? Object.keys(js.errors).map(key => (js.errors[key].awb + ': ' + js.errors[key].message)).join('</li><li>') : 'Coba beberapa saat lagi') + '</li></ul></div>',
						showConfirmButton: false,
						showCancelButton: true,
						cancelButtonText: 'Kembali'
					});
				} else {
					this.customSwal.fire({
						html: '<img src="' + this.getImage('warning') + '" class="swal2-img d-block mx-auto mb-3" /><div class="h5">Terjadi kesalahan</div><div class="w-75 m-auto">Coba beberapa saat lagi</div>',
						showConfirmButton: false,
						showCancelButton: true,
						cancelButtonText: 'Kembali'
					});
				}
			$('.loading-overlay').removeClass('show');
				this.updating = false;
			});
		}
	},
	beforeRouteUpdate(to, from, next) {
		this.customSwal.fire({
			html: '<img src="' + require('../assets/img/warning.svg') + '" class="swal2-img d-block mx-auto mb-3" /><div class="h5">Ingin membatalkan proses unggah tiket?</div>',
			showDenyButton: true,
			confirmButtonText: 'Ya, batalkan',
			denyButtonText: 'Kembali'
		}).then((result) => {
			if (result.isConfirmed) {
				next();
			} else {
				next(false);
			}
		});
	},
	components: {
		vSelect
	},
	created() {
        $('.loading-overlay').addClass('show');
		// console.log(this.$route.params);
	},
	mounted() {
		this.customSwal = Swal.mixin({
			customClass: {
				content: 'p-0',
				title: 'h4 tex-dark',
				input: 'form-control',
				actions: 'd-flex justify-content-between flex-row-reverse',
				confirmButton: 'btn btn-primary col font-weight-bold',
				denyButton: 'btn btn-outline-primary col font-weight-bold',
				cancelButton: 'btn btn-primary w-75 font-weight-bold m-auto',
			},
			buttonsStyling: false
		});

		const e = this.$refs;
		const self = this;

		if (!this.$route.params.tickets) {
			this.customSwal.fire({
				html: '<img src="' + self.getImage('overload') + '" class="swal2-img d-block mx-auto mb-3" /><div class="h6">Data AWB tiket kosong</div>',
				showConfirmButton: false,
				showCancelButton: true,
				cancelButtonText: 'Kembali'
			});
			this.$router.push({ path: '/settings/tickets/all' });
			return;
		}

		if (this.$route.params.tickets.length > 100) {
			this.customSwal.fire({
				html: '<img src="' + self.getImage('overload') + '" class="swal2-img d-block mx-auto mb-3" /><div class="h6">Jumlah AWB yang dapat dimuat maksimal 100 tiket</div>',
				showConfirmButton: false,
				showCancelButton: true,
				cancelButtonText: 'Kembali'
			}).then((result) => {
				self.$router.push({ path: '/settings/tickets/all' });
			});
		}

		authFetch("/ticket/categories").then((res) => {
			res.json().then((json) => {
				if (json.success) {
					this.categories = json.data;
				}
			});
		});

		authFetch('/ticket/status?' + Object.keys(this.$route.params.tickets).map((key) => 'awb[]=' + encodeURIComponent(this.$route.params.tickets[key].refcode) + '&title[]=' + encodeURIComponent(this.$route.params.tickets[key].title)).join('&'))
		.then((res) => {
			if (res.status === 201) {} else if (res.status === 400) {}
			return res.json();
		})
		.then((js) => {
			var id = 0;
			self.tiket_list = self.$route.params.tickets;
			Object.keys(self.tiket_list).map((key) => {
				self.tiket_list[key].id = id++;
				self.tiket_list[key].status = js.data.status[key];
				self.tiket_list[key].category = js.data.category[key];
			});

			self.table = createTable(e.ticketBatch, {
				data: self.tiket_list,
				serverSide: false,
				selectedRowClass: "",
				frame: false,
				scrollX: true,
				displayLength: 10,
				lengthChange: false,
				filter: false,
				order: [],
				// bSort: false,
				columns: [
					{
						data: 'id',
						visible: false,
						sortable: false,
						render: function (data, type, row, meta) {
							return `<input type="checkbox" class="select-request" value="${data}" />`;
						}
					},
					{ data: 'refcode' },
					{ data: 'title' },
					{ data: 'message' },
					{
						data: 'status',
						render: function (data, type, row, meta) {
							return data !== null ? `<span class="badge badge-${['danger', 'success'][data]}">${['Tidak ditemukan', 'Pernah upload'][data]}</span>` : '';
						}
					},
					{
						data: 'id',
						sortable: false,
						render: function (data, type, row, meta) {
							return `<div class="d-inline-flex align-items-center justify-content-around w-100" style="gap: 7.5px">
								<i class="fi fi-rr-edit text-warning text-lg cursor-pointer" data-action="edit" data-id="${row}"></i>
								<i class="fi fi-rr-trash text-danger text-lg cursor-pointer" data-action="remove" data-id="${row}"></i>
							</div>`;
						},
					},
				],
				"createdRow": function( row, data, dataIndex ) {
					if (data['status'] == 0) {
						$(row).css('background-color', '#F7EBEB');
						self.errorNote = true;
					}
				},
				initComplete: function () {
					$('.loading-overlay').removeClass('show');
				}
			});
		});
	}
}
</script>
<style>
.vs__dropdown-toggle {
    /* display: block; */
    /* width: 100%; */
    min-height: calc(1.79rem + 4px);
	height: fit-content;
    padding: 0.375rem 0.75rem;
	align-items: center;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.3;
    color: #495057;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 2px solid #ced4da;
    border-radius: 0.375rem;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.vs__dropdown-toggle:has(.vs__search:focus) {
	color: #495057;
	background-color: #ffffff;
	border-color: #f6bfa9;
	outline: 0;
	box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), none;
}

.vs__dropdown-toggle:has(.is-invalid) {
    border-color: #f44336;
    padding-right: 1.79rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f44336' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f44336' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(0.325em + 0.1875rem);
    background-size: calc(0.65em + 0.375rem) calc(0.65em + 0.375rem);
}

.vs__selected-options {
	height: fit-content;
	padding: 0;
	gap: 0.375rem;
}

.vs__selected {
	height: fit-content;
	margin: 0;
}

.vs__search, .vs__search:focus {
	margin: 0;
	padding: 0;
	line-height: normal;
}

.vs__spinner, .vs__spinner:after {
	height: 3em;
	width: 3em;
}

.vs__actions {
	padding: 0;
}
</style>