<template>
    <section class="content">
    <div class="flex justify-content-center">
    <div class="overlay-wrapper">
        <div class="w-100 card">
        <div class="overlay" v-if="loadingContent"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
        <div class="card-body">
            <div v-if="infoDownload" class="form-control bos-alert-primary lh-1 d-flex align-items-center h-100" style="margin-bottom: 15px;">
                <div class="row">
                    <div class="col-auto d-flex align-items-center">
                        <i class="fi fi-rr-info d-flex align-items-center"></i>
                    </div>
                    <div class="col d-flex align-items-center">
                        <div class="text-md">
                            Semua file yang Anda unduh akan tersimpan dan dapat dilihat di <a href="/data-center" class="text-info" style="text-decoration: underline;">menu data center</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
            <div class="col-xl-12 d-flex justify-content-end mb-2">
                <div class="row form-horizontal justify-content-end">
                    <div class="col-auto col-xs-4">
                    <h4>Per Tanggal</h4>
                    </div>
                    <div class="col-md-4 col-xs-4">
                    <div class="input-group mb-3">
                        <input type="text" ref="daterange" class="form-control" style="border-right: 0"/>
                        <div class="input-group-append">
                        <div
                            class="input-group-text"
                            style="background-color: #fff">
                            <span><span class="fa fa-calendar"></span></span>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-auto col-xs-4"></div>
                    <div class="col-auto col-xs-4">
                        <div class="btn-group" ref="toolbar">
                        <button type="button" data-action="read" class="btn btn-default" v-on:click="showTable"><i class="fa fa-sync-alt"></i> Show Table</button>
                        <button type="button" data-action="export-xlsx" class="btn btn-default" :disabled="loading" v-on:click="downloadXlsx"><i class="fa fa-download"></i> <span class="d-none d-md-inline-block">Download</span></button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="mb-2"></div>
            <div class="table-responsive" :hidden="hideTable">
                <table class="table table-hover" ref="tblbospay">
                <thead>
                    <tr>
                    <th>NO. AKUN</th>
                    <th>SHIPPER</th>
                    <th>DEBIT</th>
                    <th>KREDIT</th>
                    <th>SALDO</th>
                    </tr>
                </thead>
                <tbody @click="handleClick"></tbody>
                <tfoot>
                <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                </tr>
                </tfoot>
                </table>
            </div>
        </div>
        </div>
    </div>
    </div>
    </section>
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import Daterangepicker from "daterangepicker";
import moment from "moment";
import "daterangepicker/daterangepicker.css";

export default {
    name: "Bospay",
    data() {
        return {
            dt1: "",
            dt2: "",
            dateStart: "",
            dateEnd: "",
            errors: [],
            method: "",
            jurnal: {
                coa_id: '',
                coa_no: '',
                coa_name: '',
            },
            roles: "",
            role_id: "",
            coa_id: "",
            loading: false,
            loadingContent: false,
            filter: {},
            hideTable: true,
            infoDownload: false
        };
    },
    created: function () {
        this.roles = this.$route.meta.roles;
        console.log("load initial data", this.$route);
        this.dateStart = moment().format("YYYY-MM-DD");
        this.filter.dt1 = this.dateStart;
    },
    methods: {
        onPeriode() {
            if (this.tableBospay) {
                this.tableBospay.api().ajax.reload();
            } else {
                // this.showTable();
            }
        },
        // downloadXlsx: function (e) {
        //     const self = this;
        //     self.loading = true;
        //     self.loadingContent = true;
            
        //     var filter = JSON.parse(JSON.stringify(self.filter));
        //         filter.q = this.tableBospay ? $("input[name=q]").val() : '';
        //         filter.category = this.tableBospay ? $("select[name=category]").val() : 0;

        //     var data = Object.keys(filter)
        //     .map((key) =>
        //         encodeURIComponent(key) + "=" + encodeURIComponent(filter[key])
        //     ).join("&");

        //     authFetch("/report/bospay/excell", {
        //         method: "POST",
        //         body: data,
        //     })
        //     .then((response) => {
        //         if (response.status === 201) {
        //         } else if (response.status === 400) {
        //         } else if (response.status === 500){
        //             Swal.fire("Timeout!", ``, "error");
        //             this.loading = false;
        //             this.loadingContent = false;
        //             return false;
        //         }

        //         return response.blob();
        //     })
        //     .then((blob) => {
        //     setTimeout(() => {
        //         self.loading = false;
        //     }, 1000);
        //     self.loadingContent = false;
        //     var url = window.URL.createObjectURL(blob);
        //     var a = document.createElement("a");
        //     a.href = url;
        //     a.download = "data.xlsx";
        //     document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        //     a.click();
        //     a.remove(); //afterwards we remove the element again
        //     });
        // },
        downloadXlsx: function (e) {
            const self = this;
            self.loading = true;
            self.loadingContent = true;
            self.infoDownload = false;

            var data = {
                "module_code": 'bospay',
                "filter": self.filter,
                "title": 'bospay',
                "note": JSON.stringify(self.filter),
            };

            authFetch("/accounting/jurnal/request_download", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
            .then((res) => {
                setTimeout(() => {
                    self.loading = false;
                }, 1000);
                self.loadingContent = false;
                if (res){
                    self.infoDownload = true;
                }
            });
        },
        handleClick(e) {
            const er = this.$refs;
            if (e.target.matches(".link-role")) {
            }
        },
        showTable: function () {
            if (this.tableBospay) {
                this.tableBospay.api().ajax.reload();
            } else {
                this.hideTable = false;
                const e = this.$refs;
                const self = this;
                this.tableBospay = createTable(e.tblbospay, {
                    title: "",
                    ajax: "/report/bospay",
                    roles: this.$route.params.roles,
                    toolbarEl: e.toolbar,
                    selectedRowClass: "",
                    serverSide: true,
                    frame: false,
                    processing: true,
                    language: {
                        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
                    lengthMenu: [[10, 25, 50, 100, 1000, -1], [ 10, 25, 50, 100, 1000, 'Semua' ]],
                    displayLength: 10,
                    lengthChange: true,
                    paramData: function (d) {
                        var filter = JSON.parse(JSON.stringify(self.filter));
                        for (const key in filter) {
                            d[key] = filter[key] === false ? 0: filter[key];
                        }
                    },
                    columns: [
                        { data: "coa_no" },
                        { data: "nama",
                        render: function(data, type, row, meta) {
                            return `${data} (${row.shipper_code})`;
                        } },
                        { 
                        data: "sumdebit", 
                        class:'text-right',
                        render: function(data, type, row, meta) {
                            return formatCurrency(data);
                        }
                        },
                        { 
                        data: "sumcredit", 
                        class:'text-right',
                        render: function(data, type, row, meta) {
                            return formatCurrency(data);
                        }
                        },
                        { 
                        data: "sumtotal", 
                        class:'text-right',
                        render: function(data, type, row, meta) {
                            return formatCurrency(data);
                        }
                        },
                    ],
                    filterBy: [1],
                    rowCallback: function (row, data) {},
                    footerCallback:function ( row, data, start, end, display ){
                    var api = this.api(), data;
                        // Total over this page
                        var totaldebit;
                        totaldebit = 
                            api
                            .column(2, { page: 'current'})
                            .data()
                            .reduce( function (a, b) {
                                var x = parseFloat( a ) || 0;
                                var y = parseFloat( b ) || 0;
                                return x + y;
                            }, 0);

                        var totalcredit;
                        totalcredit = api
                            .column(3, { page: 'current'})
                            .data()
                            .reduce( function (a, b) {
                                var x = parseFloat( a ) || 0;
                                var y = parseFloat( b ) || 0;
                                return x + y;
                            }, 0 );

                        var totalsaldo;
                        totalsaldo = api
                            .column(4, { page: 'current'})
                            .data()
                            .reduce( function (a, b) {
                                var x = parseFloat( a ) || 0;
                                var y = parseFloat( b ) || 0;
                                return x + y;
                            }, 0 );

                        // Update footer
                            $( api.column(2).footer() ).html(
                                'Rp '+ formatCurrency(totaldebit)
                            );
                            $( api.column(3).footer() ).html(
                                'Rp '+ formatCurrency(totalcredit)
                            );
                            $( api.column(4).footer() ).html(
                                'Rp '+ formatCurrency(totalsaldo)
                            );
                    }
                });
            }
        }
    },
    mounted() {
    const e = this.$refs;
    const self = this;
    new Daterangepicker(this.$refs.daterange, {
        startDate: moment(),
        endDate: moment(),
        singleDatePicker: true,
        showDropdowns: true,
        locale: {
            format: 'DD/MM/YYYY'
        }
        }, function (d1, d2) {
        self.dateStart = d1.format("YYYY-MM-DD");
        self.dateEnd = d2.format("YYYY-MM-DD");
        self.filter.dt1 = self.dateStart;
        self.onPeriode();
        });
    $('.loading-overlay').removeClass('show');
    },
}
</script>