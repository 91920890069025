<template>
    <section class="content">
        <div class="card card-info">
            <div class="card-body">
                <div class="row">
                    <div class="col-9">
                        <div class="row form-horizontal">
                            <div class="col-auto">
                                <label class="control-label my-2 ml-2">
                                    <h6>Filter Pencarian</h6>
                                </label>
                            </div>
                            <div class="col-auto">
                                <select class="form-control" @change="dataType($event)">
                                    <option value="last_order">Kiriman Terakhir</option>
                                </select>
                            </div>
                            <div class="col-auto">
                                <div class="input-group mb-3">
                                    <input type="text" ref="daterange" class="form-control" style="border-right: 0" />
                                    <div class="input-group-append">
                                        <div class="input-group-text" style="background-color: #fff">
                                            <span><span class="fa fa-calendar"></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 text-end">
                        <div class="btn-group float-right" ref="toolbar">
                        <button type="button" data-action="read" class="btn btn-default"><i
                                class="fa fa-sync-alt"></i></button>
                        <button type="button" data-action="export-xlsx" class="btn btn-default" :disabled="processing" v-on:click="downloadXlsx"><i class="fa fa-download"></i> <span class="d-none d-md-inline-block">Download</span></button>
                        </div>
                    </div>
                </div>
                <table class="table table-hover w-100" ref="tableuser">
                    <thead>
                        <tr>
                            <th>ID Shipper</th>
                            <th>Nama</th>
                            <th>Total</th>
                            <th>Pending</th>
                            <th>Proses</th>
                            <th>Terkirim</th>
                            <th>Retur</th>
                            <th>Diserahkan</th>
                            <th>Cancel</th>
                            <th>Problem</th>
                            <th>Kiriman Terakhir</th>
                            <th>Status</th>
                            <th>Mark</th>
							<th>Rekening</th>
                        </tr>
                    </thead>
                    <tbody @click="handleClick">
                    </tbody>
                </table>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from "@/libs/hxcore";
import $ from "jquery";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import moment from "moment";

export default {
  name: "ShipperRecap",
  data() {
    return {
      dateStart: '',
      dateEnd: '',
      errors: [],
      method: "",
      roles: "",
      filter: {},
      data_type: "last_order",
      dt1: moment().startOf("month"),
      dt2: moment(),
      processing: false,
      groups: [],
      memberType: [],
      bank: [],
      formTitle: "Tambah Shipper",
      form: {
        name: "",
        username: "",
        email: "",
        membership_type_id: "",
        kontak_hp: "",
        nomor: "",
        bank_id: 0,
        bank_rekening: "",
        bank_atasnama: "",
        alamat: "",
        active: "",
      },
    };
  },
  computed: {
    kurirLogo() {
      const fileName = "jne"; //logo.toLowerCase()

      return require(`../assets/img/${fileName}.png`); // the module request
    },
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    this.filter.datatype = this.data_type;
    this.filter.dt1 = !this.filter.dt1 ? moment().startOf("month").format("YYYY-MM-DD") : this.filter.dt1;
    this.filter.dt2 = !this.filter.dt2 ? moment().format("YYYY-MM-DD") : this.filter.dt2;
  },
  methods: {
    getKurirLogo(kurir) {
        const fileName = kurir.toLowerCase();

        return require(`../assets/img/${fileName}.png`); // the module request
    },
    handleClick(e) {
        if (e.target.matches(".link-role")) {
            this.$router.push({ path: "/permit/" + e.target.dataset.id });
            return false;
        }
    },
    downloadXlsx: function (e) {
        const self = this;
        self.processing = true;
        var filter = JSON.parse(JSON.stringify(self.filter));
            filter.category = $("select[name=category]").val();
            filter.q = $("input[name=q]").val();

        var data = Object.keys(filter)
            .map((key) =>
                encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))).join("&");
        authFetch("/report/kiriman/excell", {
            method: "POST",
            body: data,
        }).then((response) => response.blob())
        .then((blob) => {
        setTimeout(() => {
            self.processing = false;
        }, 1000);
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = "data.xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
        });
    },
    dataType(event) {
        this.data_type = event.target.value;
        this.filter.datatype = this.data_type;
        this.table.api().ajax.reload();
    },
    onDate() {
        this.table.api().ajax.reload();
    },
  },
  mounted() {
    const e = this.$refs;
    var self = this;

    new Daterangepicker(
        this.$refs.daterange, {
            startDate: this.dt1,
            endDate: this.dt2,
            locale: {
                format: 'DD/MM/YYYY'
            },
        },
        function (d1, d2) {
            self.dateStart = d1.format("YYYY-MM-DD");
            self.dateEnd = d2.format("YYYY-MM-DD");
            self.data_type = self.data_type;
            self.filter.datatype = self.data_type;
            self.filter.dt1 = self.dateStart;
            self.filter.dt2 = self.dateEnd;
            self.onDate();
        }
    );
    console.log("logo", self.getKurirLogo("jne"));
    this.table = createTable(e.tableuser, {
            title: "Shipper Recap",
            roles: ["read"],
            ajax: "/shipper/recap",
            frame: false,
            scrollX: true,
            toolbarEl: e.toolbar,
            filter: true,
            stateSave: true,
            processing: true,
            language: {
                processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'
            },
            filterBy: [0,1,13],
            paramData: function (d) {
                var filter = JSON.parse(JSON.stringify(self.filter));
                for (const key in filter) {
                    d[key] = filter[key] === false ? 0 : filter[key];
                }
            },
            columns: [
                { data: "shipper_code" },
                { data: "display_name", 
                    render: function (data, type, row, meta) {
        		        return data+'<br>'+row.kontak_hp;
                }, },
                { data: "total", 
                    render: function (data, type, row, meta) {
        		        return parseInt(data) + parseInt(row.value.split(',')[0]);
                }, },
                { data: "pending", 
                    render: function (data, type, row, meta) {
        		        return parseInt(data) + parseInt(row.value.split(',')[1]);
                }, },
                { data: "process", 
                    render: function (data, type, row, meta) {
        		        return parseInt(data) + parseInt(row.value.split(',')[2]);
                }, },
                { data: "sent", 
                    render: function (data, type, row, meta) {
        		        return parseInt(data) + parseInt(row.value.split(',')[3]);
                }, },
                { data: "rts", 
                    render: function (data, type, row, meta) {
        		        return parseInt(data) + parseInt(row.value.split(',')[4]);
                }, },
                { data: "returned", 
                    render: function (data, type, row, meta) {
        		        return parseInt(data) + parseInt(row.value.split(',')[5]);
                }, },
                { data: "cancel", 
                    render: function (data, type, row, meta) {
        		        return parseInt(data) + parseInt(row.value.split(',')[7]);
                }, },
                { data: "problem", 
                    render: function (data, type, row, meta) {
        		        return parseInt(data) + parseInt(row.value.split(',')[6]);
                }, },
                { data: "last_order" },
                { data: "active",
                    render: function (data, type, row, meta) {
			            return '<span class="badge badge-' + (data == 1 ? 'info' : 'danger') + '">' + (data == 1 ? 'Active' : 'Not Active') + '</span>' + (row.ktp_verify == '1' ? ' <span class="badge badge-success">Verified</span>' : '');
                    },
                },
                { data: "marking" },
                { data: "rekening",
                    render: function (data, type, row, meta) {
                        return data['bank_rekening'] !== undefined ? data['bank_atasnama'] + '<br>' + data['bank_rekening'] : '-';
                    }
                },
            ],
            rowCallback: function (row, data) {
            },
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
        });
    },
};
</script>
<style type="text/css">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>