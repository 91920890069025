var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content"},[_c('div',{staticClass:"flex justify-content-center"},[_c('div',{staticClass:"overlay-wrapper"},[_c('div',{staticClass:"w-100 card"},[(_vm.loadingContent)?_c('div',{staticClass:"overlay"},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"card-body"},[(_vm.infoDownload)?_c('div',{staticClass:"form-control bos-alert-primary lh-1 d-flex align-items-center h-100",staticStyle:{"margin-bottom":"15px"}},[_vm._m(1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-end mb-2"},[_c('div',{staticClass:"row form-horizontal justify-content-end"},[_vm._m(2),_c('div',{staticClass:"col-auto col-xs-4"},[_c('div',{staticClass:"input-group mb-3"},[_c('input',{ref:"daterange",staticClass:"form-control",staticStyle:{"border-right":"0"},attrs:{"type":"text"}}),_vm._m(3)])]),_c('div',{staticClass:"col-auto col-xs-4"}),_c('div',{staticClass:"col-auto col-xs-4"},[_c('div',{ref:"toolbar",staticClass:"btn-group"},[_c('button',{staticClass:"btn btn-default",attrs:{"type":"button","data-action":"read"},on:{"click":_vm.showTable}},[_c('i',{staticClass:"fa fa-sync-alt"}),_vm._v(" Show Table ")]),_c('button',{staticClass:"btn btn-default",attrs:{"type":"button","data-action":"export-xlsx","disabled":_vm.loading},on:{"click":_vm.downloadXlsx}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" "),_c('span',{staticClass:"d-none d-md-inline-block"},[_vm._v("Download")])])])])])])]),_c('div',{staticClass:"mb-2"}),_c('div',{staticClass:"table-responsive",attrs:{"hidden":_vm.hideTable}},[_c('table',{ref:"tblppnkeluaran",staticClass:"table table-hover",staticStyle:{"width":"100%"}},[_vm._m(4),_c('tbody',{on:{"click":_vm.handleClick}}),_vm._m(5)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticStyle:{"font-size":"40px"}},[_vm._v("Sedang diproses "),_c('i',{staticClass:"fas fa-sync-alt fa-spin"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto d-flex align-items-center"},[_c('i',{staticClass:"fi fi-rr-info d-flex align-items-center"})]),_c('div',{staticClass:"col d-flex align-items-center"},[_c('div',{staticClass:"text-md"},[_vm._v(" Semua file yang Anda unduh akan tersimpan dan dapat dilihat di "),_c('a',{staticClass:"text-info",staticStyle:{"text-decoration":"underline"},attrs:{"href":"/data-center"}},[_vm._v("menu data center")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-auto col-xs-4"},[_c('h4',[_vm._v("Periode Laporan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-append"},[_c('div',{staticClass:"input-group-text",staticStyle:{"background-color":"#fff"}},[_c('span',[_c('span',{staticClass:"fa fa-calendar"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("TGL JURNAL")]),_c('th',[_vm._v("SHIPPER ID")]),_c('th',[_vm._v("NAMA")]),_c('th',[_vm._v("NO. JURNAL")]),_c('th',[_vm._v("REFERENSI")]),_c('th',[_vm._v("TGL TERAKHIR")]),_c('th',[_vm._v("STATUS KIRIMAN")]),_c('th',[_vm._v("PPN COD FEE")]),_c('th',[_vm._v("NILAI")]),_c('th',[_vm._v("PPN")]),_c('th',[_vm._v("TOTAL")]),_c('th',[_vm._v("KETERANGAN")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tfoot',[_c('tr',[_c('th',{attrs:{"colspan":"8"}}),_c('th'),_c('th',{attrs:{"colspan":"2"}})])])
}]

export { render, staticRenderFns }