<template>
		<section class="content">
			<div class="card card-info">
				<div class="card-header">
					<div class="box-tools card-tools toolbar">
						<div class="btn-group">
							<button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<i class="fas fa-plus"></i> Buat Jurnal
							</button>
							<div class="dropdown-menu dropdown-menu-right">
								<router-link to="/report/form-jurnal" class="dropdown-item">
									<button type="button" class="btn btn-sm dropdown-item" > 
										<i class="fas fa-edit"></i> Input Manual
									</button>
								</router-link>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body">
					<div class="row mb-3 justify-content-start" style="row-gap: .5rem;">
						<div class="col-12 col-sm-auto">
							<label class="control-label my-2 ml-2 font-weight-normal">Filter Tanggal :</label>
						</div>
						<div class="col-12 col-sm-auto form-group mb-0">
							<div class="input-group">
								<input type="text" ref="daterange" class="form-control border-right-0" />
								<div class="input-group-append">
									<div class="input-group-text rounded-right d-flex align-items-center border-left-0 pl-1 bg-white">
										<i class="fi fi-rr-calendar d-flex"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
					<table class="table table-hover" ref="tbljurnalgroup" style="min-width: 100%;">
						<thead>
							<tr>
								<th>TANGGAL</th>
								<th>NO. JURNAL</th>
								<th>MITRA</th>
								<th>REFERENSI</th>
								<th>JURNAL TYPE</th>
								<th>NOTE</th>
								<th>TINDAKAN</th>
							</tr>
						</thead>
						<tbody @click="handleClick"></tbody>
					</table>
				</div>
			</div>
		</section>
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import moment from "moment";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import $ from "jquery";

export default {
	name: "JurnalGroup",
	data() {
		return {
			errors: [],
			method: "",
			roles: "",
			groups: [],
			formTitle: "Tambah",
			form: {
				courier_id: "",
				kode: "",
				nama: "",
			},
			filter: {
				dt1: moment().startOf("month").format("YYYY-MM-DD"),
				dt2: moment().format("YYYY-MM-DD")
			},
		};
	},
	created: function () {
		this.roles = this.$route.meta.roles;
		console.log("load initial data", this.$route);
	},
	methods: {
		handleClick(e) {
			if (e.target.closest("button")) {
				if (e.target.dataset.action == "edit") {
					let id = e.target.dataset.id;
					this.$router.push({ path: `/report/form-jurnal/${id}`});
				} else if (e.target.dataset.action == "balik") {
					let id = e.target.dataset.id;
					this.$router.push({ path: `/report/form-jurnal/balik/${id}`});
				} else if (e.target.dataset.action == "duplikat") {
					let id = e.target.dataset.id;
					this.$router.push({ path: `/report/form-jurnal/duplikat/${id}`});
				} else if (e.target.dataset.action == "hapus") {
					Swal.fire({
						title: "Hapus data?",
						icon: "question",
						denyButtonText: '<i class="fa fa-times"></i> Hapus',
						showCancelButton: true,
						showDenyButton: true,
						showConfirmButton: false,
					}).then((result) => {
						if (result.isDenied) {
							authFetch("/accounting/jurnal/group/" + e.target.dataset.id, {
								method: "DELETE",
								body: "id=" + e.target.dataset.id,
							})
							.then((res) => {
								return res.json();
							})
							.then((js) => {
								this.table.api().ajax.reload();
							});
						}
					});
				}
			}
		},
	},
	mounted() {
		const e = this.$refs;
		var self = this;

		new Daterangepicker(
			this.$refs.daterange,
			{
				startDate: this.dateStart ? this.dateStart : moment().startOf("month"),
				endDate: this.dateEnd ? this.dateEnd : moment(),
				locale: {
					format: 'DD/MM/YYYY'
				},
			},
			function (d1, d2) {
				self.dateStart = d1;
				self.dateEnd = d2;
				self.filter.dt1 = d1.format("YYYY-MM-DD");
				self.filter.dt2 = d2.format("YYYY-MM-DD");
				self.table.api().ajax.reload();
			}
		);

		this.table = createTable(e.tbljurnalgroup, {
			title: "",
			// roles: this.$route.params.roles,
			frame: false,
			ajax: "/accounting/jurnal/group",
			processing: true,
			selectedRowClass: "",
			language: {
				processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
			scrollX: true,
			order: [[0, 'desc']],
			paramData: function (d) {
				var filter = JSON.parse(JSON.stringify(self.filter));
				for (const key in filter) {
					d[key] = filter[key] === false ? 0 : filter[key];
				}
			},
			columns: [
				{ data: "journal_date" },
				{ data: "journal_no" },
				{ data: "mitra_name" },
				{ data: "referensi" },
				{ data: "jurnal_type" },
				{ data: "note" },
				{ data: "action", sortable: false,
				render: function (data, type, row, meta) {
					if (row.bospay != null){
						var dropdown =
						`<div class="btn-group">
						<button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						Klik
						</button>
						<div class="dropdown-menu dropdown-menu-right">
						<button type="button" class="btn btn-sm btn-danger dropdown-item" data-action="balik" data-id="` +
								row.id +
								`"> Jurnal Balik </button>
						<button type="button" class="btn btn-sm btn-danger dropdown-item" data-action="duplikat" data-id="` +
								row.id +
								`"> Duplikat Jurnal </button>
						</div>
						</div>`;
					} else {
						var dropdown =
						`<div class="btn-group">
						<button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						Klik
						</button>
						<div class="dropdown-menu dropdown-menu-right">
						<button type="button" class="btn btn-sm btn-warning dropdown-item" data-action="edit" data-id="` +
								row.id +
								`"> Edit </button>
						<button type="button" class="btn btn-sm btn-danger dropdown-item" data-action="balik" data-id="` +
								row.id +
								`"> Jurnal Balik </button>
						<button type="button" class="btn btn-sm btn-danger dropdown-item" data-action="duplikat" data-id="` +
								row.id +
								`"> Duplikat Jurnal </button>
						</div>
						</div>`;
					}
					return dropdown;
				}, },
			],
			filterBy: [1,2,3, 5],
			rowCallback: function (row, data) {},
			initComplete: function () {
					$('.loading-overlay').removeClass('show');
			},
		});
	},
};
</script>