<template>
	<section class="content">
		<div class="card">
			<div class="card-body">
				<div class="row mb-3 justify-content-end" style="row-gap: .5rem;">
					<div class="col-12 col-sm-auto">
						<label class="control-label my-2 ml-2 font-weight-normal">Filter Tanggal :</label>
					</div>
					<div class="col-12 col-sm-auto form-group mb-0">
						<div class="input-group">
							<input type="text" ref="daterange" class="form-control border-right-0" />
							<div class="input-group-append">
								<div class="input-group-text rounded-right d-flex align-items-center border-left-0 pl-1 bg-white">
									<i class="fi fi-rr-calendar d-flex"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-auto form-group mb-0 mr-auto">
						<select class="form-control" required v-model="filter.status" @change="table.api().ajax.reload()">
							<option value="" selected disabled hidden>Status</option>
							<option v-for="status in listStatus" v-bind:key="status.id" v-bind:value="status.id">
								{{ status.label }}
							</option>
						</select>
					</div>
					<div class="col col-sm-auto text-right">
						<div class="btn-group" ref="toolbar">
							<button type="button" data-action="read" class="btn btn-outline-primary border-right-0" v-if="false">
								<i class="fi fi-rr-refresh d-flex"></i>
							</button>
							<button type="button" :disabled="loading" v-on:click="downloadXlsx" data-action="export-xlsx" class="btn btn-outline-primary">
								<span v-if="loading" class="spinner-border spinner-border-sm"></span>
								<i v-else class="fi fi-rr-file-download"></i>
								Download
							</button>
						</div>
					</div>
					<div class="col-6 col-sm-auto">
						<button type="button" :disabled="loading" @click="$router.push({ path: '/form-voucher' })" data-action="export-xlsx" class="btn btn-primary">
							<span v-if="loading" class="spinner-border spinner-border-sm"></span>
							<i v-else class="fi fi-rr-plus"></i>
							Buat Voucher
						</button>
					</div>
				</div>

				<table class="table table-hover w-100" id="tableRequestApproval" ref="tblrequestjurnal">
					<thead>
						<tr>
							<th>
								<input type="checkbox" id="checkall" ref="checkall" name="checkall" value="1" @click="selectAll" />
							</th>
							<th>Judul</th>
							<th>Deskripsi</th>
							<th>Nominal</th>
							<th>Kode</th>
							<th>Periode</th>
							<th>Kuota</th>
							<th>Status</th>
							<th>Aksi</th>
						</tr>
					</thead>
					<tbody @click="handleClick"></tbody>
				</table>
				<div class="text-center" :class="{ 'd-none' : emptyNotes }">
					<img :src="getImage('empty')" width="187.5px" />
					<div class="h5 font-weight-bold">Blast Voucher kosong</div>
					<p>Saat ini masih belum ada data Blast Voucher</p>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import moment from "moment";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import "icheck-material";
import $ from "jquery";

export default {
	name: "BlastVoucher",
	data() {
		return {
			dt1: moment().startOf("month"),
			dt2: moment(),
			emptyNotes: false,
			loading: false,
			listStatus: [
				{
					id: '0',
					label: 'Draft'
				},
				{
					id: '1',
					label: 'Aktif'
				},
				{
					id: '2',
					label: 'Nonaktif'
				},
				{
					id: '3',
					label: 'Expired'
				},
				{
					id: '',
					label: 'Semua'
				}
			],
			filter: {
				status: ''
			},
		};
	},
	created: function () {
		this.dateStart = this.dt1.format('YYYY-MM-DD');
		this.dateEnd = this.dt2.format('YYYY-MM-DD');
	},
	methods: {
		handleClick: async function(e) {
			const self = this;
			if (e.target.matches("input[type=checkbox]")) {
				const checkboxes = document.querySelectorAll('.select-request:checked');
				if (checkboxes.length == 0) {
					this.$refs.checkall.checked = false;
				} else {
					this.$refs.checkall.checked = true;
				}
				this.selectedCount = checkboxes.length;
			} else if (e.target.matches(".voucher-edit")) {
				let route = this.$router.resolve({
					path: "/form-voucher/" + e.target.dataset.id
				});
				window.open(route.href, "_blank");
			} else if (e.target.matches(".voucher-delete")) {
				this.processing = true;
				const status = e.target.dataset.status;
				await this.customSwal.fire({
					html: '<img src="' + this.getImage(status == 1 ? 'warning' : 'delete') + '" class="d-block ' + (status == 1 ? 'swal2-img' : 'w-25') + ' mx-auto mb-3" /><div class="h5">' + (status == 1 ? 'Voucher aktif tidak bisa dihapus' : 'Yakin ingin menghapus voucher?') + '</div>',
					showConfirmButton: status != 1,
					showDenyButton: status != 1,
					showCancelButton: status == 1,
					confirmButtonText: 'Hapus\u0020Voucher',
					denyButtonText: 'Batal',
					cancelButtonText: 'Oke'
				})
				.then((result) => {
					if (result.isConfirmed) {
						authFetch('/shipper/voucher', {
							method: 'DELETE',
							body: 'voucher_code=' + e.target.dataset.id
						})
						.then((res) => {
							if (res.status === 201) {} else if (res.status === 400) {}
							return res.json();
						})
						.then((js) => {
							this.customSwal.fire({
								html: '<img src="' + this.getImage(js.success ? 'ok' : 'overload') + '" class="swal2-img d-block mx-auto mb-3" /><div class="h5">' + (js.success ? js.message : js.type == 'error_input' ? 'Data tidak lengkap' : 'Terjadi kesalahan') + '</div><div class="w-75 m-auto" style="max-height: 100px;overflow-y: scroll;">' + (js.details ? '<ul class="text-left"><li>' + Object.keys(js.details).map(key => js.details[key]).join('</li><li>') + '</li></ul>' : '') + '</div>',
								showConfirmButton: false,
								showCancelButton: true,
								cancelButtonText: js.success ? 'Oke' : 'Coba lagi'
							})
							.then((js) => {
								self.table.api().ajax.reload();
							});
						});
					}
				});
			}
		},
		selectAll: function (e) {
			const val = e.target.checked;
			const checkboxes = document.querySelectorAll('.select-request');

			for (var i = 0; i < checkboxes.length; i++) {
				checkboxes[i].checked = val;
			}

			if (val && checkboxes.length > 0) {
				this.selectedCount = checkboxes.length;
			} else {
				this.selectedCount = 0;
			}

			if (this.selectedCount > 0) {
				// $(this.$refs.btnSelected).show();
			} else {
				// $(this.$refs.btnSelected).hide();
			}
		},
		getImage(file) {
			return require(`../assets/img/${file}.svg`); // the module request
		},
		downloadXlsx: function (e) {
			var table = $('#tableRequestApproval').DataTable();
			var length = table.page.info().recordsTotal;

			if (length > 20000) {
				Swal.fire("Proses gagal", `Maksimal data Request sebanyak kurang lebih 20.000 data.`, "error");
				return false;
			}

			var filter = JSON.parse(JSON.stringify(this.filter));
				filter.q = $("input[name=q]").val();
				filter.category = $("select[name=category]").val();

			var data = Object.keys(filter)
				.map(
					(key) =>
					encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
				)
				.join("&");

			this.loading = true;

			authFetch("/shipper/voucher/excell", {
				method: "POST",
				body: data,
			})
			.then((response) => {
				if (response.status === 201) {
				} else if (response.status === 400) {
				} else if (response.status === 500){
					Swal.fire("Timeout!", ``, "error");
					return false;
				}

				return response.blob();
			})
			.then((blob) => {
				this.loading = false;
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement("a");
				a.href = url;
				a.download = "Blast Voucher.xlsx";
				document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
				a.click();
				a.remove(); //afterwards we remove the element again
			});
		}
	},
	mounted() {
		const e = this.$refs;
		const self = this;

		new Daterangepicker(
			this.$refs.daterange,
			{
				startDate: !this.dt1 ? moment().startOf("month") : this.dt1,
				endDate: !this.dt2 ? moment() : this.dt2,
				locale: {
					format: 'DD/MM/YYYY'
				},
			},
			function (d1, d2) {
				self.dateStart = d1.format("YYYY-MM-DD");
				self.dateEnd = d2.format("YYYY-MM-DD");
				self.filter.dt1 = self.dateStart;
				self.filter.dt2 = self.dateEnd;
				self.table.api().ajax.reload();
			}
		);

		this.table = createTable(e.tblrequestjurnal, {
			roles: self.$route.params.roles,
			ajax: "/shipper/voucher",
			frame: false,
			toolbarEl: e.toolbar,
			scrollX: true,
			processing: true,
			language: {
			processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
			filterBy: [1, 2, 4],
			order: [[0, 'desc']],
			paramData: function (d) {
				var filter = JSON.parse(JSON.stringify(self.filter));
				d.dt1 = self.dateStart;
				d.dt2 = self.dateEnd;
				var filter = JSON.parse(JSON.stringify(self.filter));
				for (const key in filter) {
					d[key] = filter[key] === false ? 0 : filter[key];
				}
			},
			columns: [
				{
					data: "id",
					visible: false,
					sortable: false,
					render: function (data, type, row, meta) {
						return row.status == '0' ? '<input type="checkbox" class="select-request" value="' + data + '" />' : '';
					}
				},
				{ data: "name" },
				{ data: "description" },
				{
					data: "voucher_value",
					render: function (data, type, row, meta) {
						var minus = data < 0;
						var formatRupiah = (minus ? '-' : '') + 'Rp\u0020' + formatCurrency(data);
						return '<span class="text-' + (minus ? 'danger' : 'inherit') + '">' + formatRupiah + '</span>';
					}
				},
				{ data: "voucher_code" },
				{
					data: null,
					render: function (data, type, row, meta) {
						return moment(row.start_date).format('DD/MM/YYYY') + ' s/d ' + moment(row.end_date).format('DD/MM/YYYY');
					}
				},
				{
					data: "qty",
					render: function (data, type, row, meta) {
						return data == null ? 'Unlimited' : data;
					}
				},
				{
					data: "active",
					render: function (data, type, row, meta) {
						var status = ['Draft', 'Aktif', 'Nonaktif', 'Expired'];
						var color = ['light', 'success', 'danger', 'secondary'];
						return '<span class="badge badge-' + color[data] + '">' + status[data] + '</span>';
					}
				},
				{
					data: "voucher_code",
					sortable: false,
					render: function (data, type, row, meta) {
						return `<div class="d-inline-flex align-items-center" style="gap: 7.5px">
							<i class="fi fi-rr-edit h5 mb-0 text-primary cursor-pointer voucher-edit" data-id="${row.id}"></i>
							<i class="fi fi-rr-trash h5 mb-0 text-primary cursor-pointer voucher-delete" data-id="${data}" data-status="${row.active}"></i>
						</div>`;
					},
				},
			],
			drawCallback: function (row, data) {
				self.emptyNotes = row._iRecordsTotal > 0;
				e.checkall.checked = false;
			},
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
		});

		this.customSwal = Swal.mixin({
			customClass: {
				content: 'p-0',
				title: 'h4 tex-dark',
				input: 'form-control',
				actions: 'd-flex justify-content-between flex-row-reverse',
				confirmButton: 'btn btn-primary col font-weight-bold',
				denyButton: 'btn btn-outline-primary col font-weight-bold',
				cancelButton: 'btn btn-primary w-75 font-weight-bold m-auto',
			},
			buttonsStyling: false
		});
	}
};
</script>