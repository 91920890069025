<template>
	<section class="content">
		<div class="card">
			<div class="card-body">
				<div class="row mb-3 justify-content-end" style="row-gap: .5rem;">
					<div class="col-12 col-sm-auto">
						<label class="control-label my-2 ml-2 font-weight-normal">Filter Tanggal :</label>
					</div>
					<div class="col-12 col-sm-auto form-group mb-0">
						<div class="input-group">
							<input type="text" ref="daterange" class="form-control border-right-0" />
							<div class="input-group-append">
								<div class="input-group-text rounded-right d-flex align-items-center border-left-0 pl-1 bg-white">
									<i class="fi fi-rr-calendar d-flex"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-auto form-group mb-0 mr-auto">
						<select class="form-control" required v-model="filter.status" @change="showTable">
							<option value="" selected disabled hidden>Status</option>
							<option v-for="status in listStatus" v-bind:key="status.id" v-bind:value="status.id">
								{{ status.label }}
							</option>
						</select>
					</div>
					<div class="col col-sm-auto text-right">
						<div class="btn-group" ref="toolbar">
							<button type="button" data-action="read" class="btn btn-outline-primary border-right-0" v-if="false">
								<i class="fi fi-rr-refresh d-flex"></i>
							</button>
							<button type="button" :disabled="loading" v-on:click="downloadXlsx" data-action="export-xlsx" class="btn btn-primary">
								<span v-if="loading" class="spinner-border spinner-border-sm"></span>
								<i v-else-if="false" class="fi fi-rr-file-download"></i>
								Download
							</button>
						</div>
					</div>
				</div>

				<table class="table table-hover w-100" id="table" ref="table">
					<thead>
						<tr>
							<th>
								<input type="checkbox" id="checkall" ref="checkall" name="checkall" value="1" @click="selectAll" />
							</th>
							<th>Nomor AWB</th>
							<th>Resi</th>
							<th>Kurir</th>
							<th>Destinasi Pengiriman</th>
							<th>Nama Shipper</th>
							<th>Penerima</th>
							<th>Status </th>
							<th>Tanggal Order</th>
							<th>Terlambat</th>
							<th>SLA</th>
						</tr>
					</thead>
					<tbody @click="handleClick"></tbody>
				</table>
				<div class="text-center" :class="{ 'd-none' : emptyNotes }">
					<img :src="getImage('empty')" width="187.5px" />
					<div class="h5 font-weight-bold">SLA Monitoring kosong</div>
					<p>Saat ini masih belum ada data SLA Monitoring</p>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import moment from "moment";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import "icheck-material";
import $ from "jquery";

export default {
	name: "SlaMonitoring",
	data() {
		return {
			dateStart: moment().startOf("month"),
			dateEnd: moment(),
			filter: {
				dt1: moment().startOf("month").format("YYYY-MM-DD"),
				dt2: moment().format("YYYY-MM-DD"),
				status: ''
			},
			emptyNotes: false,
			loading: false,
			listStatus: [
				{
					id: '00',
					label: 'Menunggu Drop'
				},
				{
					id: '01',
					label: 'Menunggu Pick-up'
				},
				{
					id: '1',
					label: 'Proses Pengiriman'
				},
				// {
				// 	id: '2',
				// 	label: 'Terkirim'
				// },
				{
					id: '3',
					label: 'Proses Retur'
				},
				// {
				// 	id: '40',
				// 	label: 'Retur Diserahkan'
				// },
				// {
				// 	id: '41',
				// 	label: 'Retur Diterima'
				// },
				{
					id: '5',
					label: 'Bermasalah'
				},
				{
					id: '',
					label: 'Semua'
				}
			]
		};
	},
	created: function () {
	},
	methods: {
		handleClick(e) {
			if (e.target.matches(".link-order")) {
				let route = this.$router.resolve({
					path: "/order/detail/" + e.target.dataset.id
				});
				window.open(route.href,"_blank");
			} else if (e.target.matches("input[type=checkbox]")) {
				var checkboxes = document.querySelectorAll('.select-request:checked');
				if (checkboxes.length == 0) {
					this.$refs.checkall.checked = false;
				} else {
					this.$refs.checkall.checked = true;
				}
				this.selectedCount = checkboxes.length;
			}
		},
		selectAll: function (e) {
			var val = e.target.checked;
			var checkboxes = document.querySelectorAll('.select-request');

			for (var i = 0; i < checkboxes.length; i++) {
				checkboxes[i].checked = val;
			}

			if (val && checkboxes.length > 0) {
				this.selectedCount = checkboxes.length;
			} else {
				this.selectedCount = 0;
			}

			if (this.selectedCount > 0) {
				// $(this.$refs.btnSelected).show();
			} else {
				// $(this.$refs.btnSelected).hide();
			}
		},
		getImage(file) {
			return require(`../assets/img/${file}.svg`); // the module request
		},
		getKurirLogo(kurir) {
			if (!kurir) return;
			const fileName = kurir.toLowerCase();
			return require(`../assets/img/${fileName}.png`); // the module request
		},
		infoStatus(row) 
		{
			if (row.tracking_status == 0 && row.pickup_status == 1) {
				return '<span class="badge badge-secondary">' + row.status + '</span>';
			} else if (row.tracking_status == 1) {
				return '<span class="badge badge-warning">' + row.status + '</span>';
			} else if (row.tracking_status == 2) {
				return '<span class="badge badge-success">' + row.status + '</span>';
			} else if (row.tracking_status == 3) {
				return '<span class="badge badge-warning">' + row.status + '</span>';
			} else if (row.tracking_status == 4 && row.return_received == 0) {
				return '<span class="badge badge-info">' + row.status + '</span>';
			} else if (row.tracking_status == 4 && row.return_received == 1) {
				return '<span class="badge badge-success">' + row.status + '</span>';
			} else if (row.tracking_status == 5) {
				return '<span class="badge badge-danger">' + row.status + '</span>';
			}
		},
		showTable() {
			this.table.api().ajax.reload();
		},
		downloadXlsx: function (e) {
			var table = $('#table').DataTable();
			var length = table.page.info().recordsTotal;

			if (length > 20000) {
				Swal.fire("Proses gagal", `Maksimal data Request sebanyak kurang lebih 20.000 data.`, "error");
				return false;
			}

			var filter = JSON.parse(JSON.stringify(this.filter));
				filter.q = $("input[name=q]").val();
				filter.category = $("select[name=category]").val();

			console.log(filter);
			var data = Object.keys(filter)
				.map(
					(key) =>
					encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
				)
				.join("&");
			console.log(data);
			this.loading = true;
			authFetch("/report/order/excell_sla", {
				method: "POST",
				body: data,
			})
			.then((response) => {
				if (response.status === 201) {
				} else if (response.status === 400) {
				} else if (response.status === 500){
					Swal.fire("Timeout!", ``, "error");
					return false;
				}

				return response.blob();
			})
			.then((blob) => {
				this.loading = false;
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement("a");
				a.href = url;
				a.download = "SLA Monitoring.xlsx";
				document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
				a.click();
				a.remove(); //afterwards we remove the element again
			});
		}
	},
	mounted() {
		const e = this.$refs;
		var self = this;

		new Daterangepicker(
			this.$refs.daterange,
			{
				startDate: this.dateStart ? this.dateStart : moment().day(-31),
				endDate: this.dateEnd ? this.dateEnd : moment(),
				locale: {
					format: 'DD/MM/YYYY'
				},
			},
			function (d1, d2) {
				self.dateStart = d1;
				self.dateEnd = d2;
				self.filter.dt1 = d1.format("YYYY-MM-DD");
				self.filter.dt2 = d2.format("YYYY-MM-DD");
				self.table.api().ajax.reload();
			}
		);

		this.table = createTable(e.table, {
			ajax: "/report/order/sla",
			frame: false,
			toolbarEl: e.toolbar,
			scrollX: true,
			processing: true,
			language: {
				processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'
			},
			filterBy: [2, 5, 4, 3],
			order: [[8, 'desc']],
			paramData: function (d) {
				var filter = JSON.parse(JSON.stringify(self.filter));
				for (const key in filter) {
					d[key] = filter[key] === false ? 0 : filter[key];
				}
			},
			columns: [
				{
					data: null,
					visible: false,
					sortable: false,
					render: function (data, type, row, meta) {
						return row.status == '0' ? '<input type="checkbox" class="select-request" value="' + data + '" />' : '';
					}
				},
				{
					data: "no_resi",
					render: function (data, type, row, meta) {
						return (`<span class="link link-order text-info" style="cursor:pointer;" data-id="${row.order_no}">${data}</span>`);
					}
				},
				{ data: "no_resi", visible: false },
				{
					data: "prefix",
					render: function (data, type, row, meta) {
						if (!data) return '';
						var logo = data ? data.toLowerCase() : '';
						return '<img class="align-center" style="max-width: 3.5rem" src="' + self.getKurirLogo(logo) + '" />';
					}
				},
				{
					data: null,
					render: function (data, type, row, meta) {
						return row.receiver_kecamatan + ', ' + row.receiver_kota + ', ' + row.receiver_propinsi;
					}
				},
				{
					data: null,
					render: function (data, type, row, meta) {
						return `${row.nama}<div class="text-xs">ID\u00A0:\u00A0${row.shipper_code}</div>`;
					}
				},
				{
					data: null,
					render: function (data, type, row, meta) {
						return `${row.receiver_name}<div class="text-xs">${row.receiver_phone}\u00A0<i class="fi fi-brands-whatsapp text-success"></i></div>`;
					}
				},
				{
					data: "status",
					render: function (data, type, row, meta) {
						return self.infoStatus(row);
					}
				},
				{
					data: "ordered_on",
					render: function (data, type, row, meta) {
						return moment(data).format('DD/MM/YYYY');
					}
				},
				{
					data: "aging",
					render: function (data, type, row, meta) {
						return '<span class="badge badge-danger">' + (data ? data : 0) + ' Hari</span>';
					}
				},
				{
					data: "sla",
					render: function (data, type, row, meta) {
						return '<span class="badge badge-success">' + data + ' Hari</span>';
					}
				},
			],
			drawCallback: function (row, data) {
				self.emptyNotes = row._iRecordsTotal > 0;
				e.checkall.checked = false;
			},
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
		});

		this.customSwal = Swal.mixin({
			customClass: {
				content: 'p-0',
				title: 'h4 tex-dark',
				input: 'form-control',
				actions: 'd-flex justify-content-between flex-row-reverse',
				confirmButton: 'btn btn-primary col font-weight-bold',
				denyButton: 'btn btn-outline-primary col font-weight-bold',
				cancelButton: 'btn btn-primary w-75 font-weight-bold m-auto',
			},
			buttonsStyling: false
		});
	}
};
</script>