const sideMenukurir = [
    { label: 'Dashboard', url: '/dashboard-admin', icon: 'fi fi-rr-dashboard' },
    { label: 'Riwayat Kiriman', url: '/riwayat-kurir/all', icon: 'fi fi-rr-time-past' },
    { label: 'SLA Monitoring', url: '/sla-monitoring', icon: 'fi fi-rr-document' },
    {
        label: 'Tiket & Klaim',
        url: '#',
        icon: 'fi fi-rr-ticket',
        menus: [
            { label: 'Buat Tiket Baru', url: () => {
                //this.$root.$emit('createTicket', 3);
            }, icon: 'fi fi-rr-circle' },
            { label: 'Daftar Tiket', url: '/settings/tickets/all', icon: 'fi fi-rr-circle' },
            { label: 'Daftar Klaim', url: '/settings/tickets/claimcourier', icon: 'fi fi-rr-circle' },
        ]
    },
    {
        label: 'Pengaturan',
        url: '#',
        icon: 'fi fi-rr-settings',
        menus: [
            { label: 'Profil', url: '/profile', icon: 'fi fi-rr-circle' },
        ]
    }
    //{ label: 'Payment', url: '/payment-kurir', icon: 'fi fi-rr-receipt' },

];
export default sideMenukurir;
