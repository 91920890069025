<template>
	<section class="content">
		<div class="card">
			<div class="card-body">
				<div class="row mb-3" style="row-gap: .5rem;">
					<div class="col-12 col-sm-auto">
						<div class="input-group">
							<input type="text" ref="daterange" class="form-control border-right-0" />
							<div class="input-group-append">
								<div class="input-group-text rounded-right d-flex align-items-center border-left-0 pl-1 bg-white">
									<i class="fi fi-rr-calendar d-flex"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="col-auto mb-2">
						<select class="form-control" @change="dataType($event)" aria-placeholder="Filter Status">
							<option value="all"> Semua </option>
							<option value="0"> Pending </option>
							<option value="1"> Proses </option>
							<option value="2"> Selesai </option>
							<option value="3"> Gagal </option>
						</select>
					</div>
				</div>

				<table class="table table-hover w-100" id="tableDataCenter" ref="tbldatacenter">
					<thead>
						<tr>
							<th>Tanggal Request</th>
							<th>Status Download</th>
							<th>Laporan</th>
							<th>Periode</th>
							<th>Aksi</th>
						</tr>
					</thead>
					<tbody @click="handleClick"></tbody>
				</table>
				<div class="text-center" :class="{ 'd-none' : emptyNotes }">
					<img :src="getImage('empty')" width="187.5px" />
					<div class="h5 font-weight-bold">Data center kosong</div>
					<p>Saat ini masih belum ada data, tambahkan request baru</p>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import moment from "moment";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import "icheck-material";
import $ from "jquery";

export default {
	name: "JurnalGroup",
	data() {
		return {
			dt1: moment().startOf("month"),
			dt2: moment(),
			emptyNotes: false,
			loading: false,
			roles: "",
			filter: {
				dataType : "all",
			},
			data_type: ""
		};
	},
	created: function () {
		this.roles = this.$route.meta.roles;

		if (this.filter.dt1 && this.filter.dt2) {
			this.dt1 = moment(this.filter.dt1);
			this.dt2 = moment(this.filter.dt2);
		}

		this.dateStart = this.dt1.format('YYYY-MM-DD');
		this.dateEnd = this.dt2.format('YYYY-MM-DD');
	},
	methods: {
		handleClick(e) {
			if (e.target.matches(".data-center")) {	
				this.downloadXlsx(e.target.id, e.target.value);	
			} 
		},
		getImage(file) {
			return require(`../assets/img/${file}.svg`); // the module request
		},
		dataType(event) {
			this.data_type = event.target.value;
			this.filter.dataType = this.data_type;
			this.table.api().ajax.reload();
		},
		downloadXlsx: function (path, module) {
			this.loading = true;
			authFetch("/report/data_center/excell", {
				method: "POST",
				headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				body: JSON.stringify({"path" : path, "module" : module}),
			})
            .then(response => response.blob())
            .then(blob => {
				var d = new Date();
				var day = d.getDate();
				var month = d.getMonth();
				var year = d.getFullYear();
				var date_now = year+""+month+""+day;

                this.loading = false;
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = module == "piutang" ? module+'_'+ date_now +'.csv' : module+'_'+ date_now +'.xlsx';
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();    
                a.remove();  //afterwards we remove the element again         
            });
		},
		showTable: function() {
			const e = this.$refs;
			var self = this;

			this.table = createTable(e.tbldatacenter, {
				roles: this.$route.params.roles,
				ajax: "/report/data_center",
				frame: false,
				toolbarEl: e.toolbar,
				scrollX: true,
				processing: true,
				language: {
				processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
				filterBy: [2],
				order: [[0, 'desc']],
				paramData: function (d) {
					var filter = JSON.parse(JSON.stringify(self.filter));
					d.dt1 = self.dateStart;
					d.dt2 = self.dateEnd;
					var filter = JSON.parse(JSON.stringify(self.filter));
					for (const key in filter) {
							d[key] = filter[key] === false ? 0 : filter[key];
					}
				},
				columns: [
					{ 
						data: "created_on",
						width: 200,
					},
					{ 
						data: "status" ,
						textalign: 'center',
						width: 110,
						render: function (data, type, row, meta) {
						if (parseInt(data) == 1) {
							return '<span class="badge badge-warning">Proses</span>';
						} else if (parseInt(data) == 2) {
							return '<span class="badge badge-success">Selesai</span>';
						} else if (parseInt(data) == 3) {
							return '<span class="badge badge-danger">Gagal</span>';
						} else {
							return '<span class="badge badge-light">Pending</span>';
						}
					},
					},
					{ data: "title" },
					{
						data: "note",
						sortable: false,
						render: function (data, type, row, meta) {
							let res = '';

							if (!row.dt2 && !row.dt1){
								res = '<span>-</span>';
							} else if (!row.dt2){
								res = '<span>per tanggal '+row.dt1+'</span>';
							} else {
								res = '<span>'+row.dt1+' - '+row.dt1+'</span>';
							}
							return res;
						}
					},
					{
						data: "file_path",
						sortable: false,
						align: 'center',
						width: 50,
						render: function (data, type, row, meta) {
							if (parseInt(row.status) == 2){
								return `<button class="btn btn-primary data-center" data-action="1" id="${data}" value="${row.module}">Download</button>`;
							} else {
								return `<button class="btn btn-primary" disabled="true">Download</button>`;
							}
						},
					},
				],
				drawCallback: function (row, data) {
					self.emptyNotes = row._iRecordsTotal > 0;
				},
				initComplete: function () {
					$('.loading-overlay').removeClass('show');
				},
			});
		}
	},
	mounted() {
		const e = this.$refs;
		var self = this;

		new Daterangepicker(
			this.$refs.daterange,
			{
				startDate: !this.dt1 ? moment().day(-31) : this.dt1,
				endDate: !this.dt2 ? moment() : this.dt2,
				locale: {
					format: 'DD/MM/YYYY'
				},
			},
			function (d1, d2) {
				self.dateStart = d1.format("YYYY-MM-DD");
				self.dateEnd = d2.format("YYYY-MM-DD");
				self.filter.dt1 = self.dateStart;
				self.filter.dt2 = self.dateEnd;
				self.table.api().ajax.reload();
			}
		);

		this.customSwal = Swal.mixin({
			customClass: {
				content: 'p-0',
				title: 'h4 tex-dark',
				input: 'form-control',
				actions: 'd-flex justify-content-between flex-row-reverse',
				confirmButton: 'btn btn-primary col font-weight-bold',
				denyButton: 'btn btn-outline-primary col font-weight-bold',
				cancelButton: 'btn btn-primary w-75 font-weight-bold m-auto',
			},
			buttonsStyling: false
		});

		this.showTable();
	}
};
</script>