<template>
  <section class="content">
    <div class="card card-info">
        <div class="card-body">
            <div class="row" ref="toolbar">
                <div class="col-9">
                    <div class="row form-horizontal">
                        <div class="col-auto">
                            <button type="button" data-action="update" class="btn btn-info mr-2" :disabled="isDisabled"><i class="fas fa-edit"></i> <span class="d-none d-md-inline-block">Edit</span></button>
                            <button type="button" data-action="delete" class="btn btn-danger" :disabled="isDisabled"><i class="fas fa-trash"></i> <span class="d-none d-md-inline-block">Delete</span></button>
                        </div>
                    </div>
                </div>
                <div class="col-3 text-end">
                    <div class="btn-group float-right">
                    <button type="button" data-action="read" class="btn btn-default"><i
                            class="fa fa-sync-alt"></i></button>
                    <button type="button" data-action="create" class="btn btn-primary"><i class="fa fa-plus"></i> <span class="d-none d-md-inline-block">Tambah</span></button>
                    </div>
                </div>
            </div>
            <div class="p-2"></div>
            <table class="table table-hover" ref="tableusercs">
            <thead>
                <tr>
                <th>ID Mitra</th>
                <th>Nama</th>
                <th>Tipe</th>
                </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
            </table>
        </div>
    </div>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12 form-group">
                  <label class="control-label">Nama</label>
                  <input type="hidden" name="row_id" value="" />
                  <input
                    id="name"
                    class="form-control"
                    v-model="form.name"
                    type="text"
                    required="required"
                    name="name"
                    minlength="4"
                    pattern="([A-z\s]){2,}"
                    title="Tidak boleh ada angka & simbol"
                    onkeydown="return (event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
                    || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                    || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 form-group">
                  <label class="control-label">Tipe</label>
                  <select id="type" class="form-control" v-model="form.type" name="type" required="required">
                    <option :value="1">Kurir</option>
                    <option :value="2">Shipper</option>
                    <option :value="3">Pajak</option>
                    <option :value="4">Internal</option>
									</select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" :disabled="isSubmit" class="btn btn-primary">
                Simpan
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Tutup
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  name: "Mitra",
  data() {
    return {
      method: "",
      roles: "",
      formTitle: "Tambah Data List Mitra",
      form: {
        code: "",
        name: "",
        type: "",
      },
      isDisabled: true,
      isSubmit: false,
    };
  },
  
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
  },

  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
    },
    
    submitForm: function (ev) {
      const e = this.$refs;
      this.isSubmit = true;
      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      var urlSubmit = "/mitra";

      authFetch(urlSubmit, {
        method: this.method,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: data,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          if (!js.success) {
            this.isSubmit = false;

            return;
          }
          this.isSubmit = false;
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
  },

  mounted() {
    const e = this.$refs;
    var self = this;
    this.table = createTable(e.tableusercs, {
      title: "",
      roles: this.$route.params.roles,
      toolbarEl: e.toolbar,
      ajax: "/mitra",
      columns: [
        { data: "code" }, 
        { data: "name" }, 
        {
          data: "type",
          render: function (data, type, row, meta) {
							if (data == 1) {
								return '<span>Kurir</span>';
							} else if (data == 2) {
								return '<span>Shipper</span>';
							} else if (data == 3) {
								return '<span>Pajak</span>';
							}else {
								return '<span>Internal</span>';
							}
					},
        }
      ],
      frame: false,
      filterBy: [0, 1],
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      rowCallback: function (row, data) {
      },

      rowClick: function (row) {
          if (Object.keys(row.data).length > 0) {
            self.isDisabled = false;
          }
      },

      drawCallback: function() {
          self.isDisabled = true;
      },

      buttonClick: (evt) => {
        if (evt.role == "read") {
            self.isDisabled = true;
        } else if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.formTitle = "Tambah Mitra";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;
          self.method = "PUT";
          self.formTitle = "Edit Mitra";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          Swal.fire({
            title: "Hapus Mitra?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/mitra/delete", {
                method: "PUT",
                body: "code=" + evt.data.code,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      },

			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
    });
  },
};
</script>