<template>
	<section class="content">
		<div class="overlay-wrapper">
			<div class="card card-primary">
				<div class="overlay" v-if="loadingContent"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
				<div class="card-body">
					<div v-if="infoDownload" class="form-control bos-alert-primary lh-1 d-flex align-items-center h-100" style="margin-bottom: 15px;">
						<div class="row">
							<div class="col-auto d-flex align-items-center">
								<i class="fi fi-rr-info d-flex align-items-center"></i>
							</div>
							<div class="col d-flex align-items-center">
								<div class="text-md">
									Semua file yang Anda unduh akan tersimpan dan dapat dilihat di <a href="/data-center" class="text-info" style="text-decoration: underline;">menu data center</a>
								</div>
							</div>
						</div>
					</div>
					<div class="row form-horizontal">
						<div class="col-12 col-md-auto">
							<label class="control-label my-2 ml-2">
								<h6>Filter Pencarian</h6>
							</label>
						</div>
						<div class="col-12 col-md-auto mb-2">
							<div class="input-group">
								<input type="text" ref="daterange" class="form-control" style="border-right: 0" />
								<div class="input-group-append">
									<div class="input-group-text" style="background-color: #fff">
										<span><span class="fa fa-calendar"></span></span>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-md mb-2"></div>
						<div class="col-12 col-md-auto mb-2">
							<div class="btn-group" ref="toolbar">
								<button type="button" data-action="read" class="btn btn-default" v-on:click="showTable">
									<i class="fa fa-sync-alt"></i> Show Table
								</button>
								<button class="btn btn-primary" @click.prevent="downloadCsv" :disabled="downloading">
									<i class="fa fa-download"></i> Download
								</button>
							</div>
						</div>
					</div>
					<div class="table-responsive" :hidden="hideTable">
						<table class="table table-hover" ref="tablepiutang" id="tblpiutang">
							<thead>
								<tr>
									<th>AWB</th>
									<th>KURIR</th>
									<th>TGL JURNAL</th>
									<th>NILAI COD</th>
									<th>DEBIT</th>
									<th>TOTAL DEBIT PIUTANG</th>
									<th>PEMBAYARAN</th>
									<th>CREDIT</th>
									<th>TOTAL CREDIT PIUTANG</th>
									<th>SISA TAGIHAN</th>
									<th>AGING</th>
									<th>SHIPPER</th>
									<th>STATUS COD</th>
									<th>KURIR SERVICE</th>
									<th>NILAI PAKET</th>
									<th>STATUS TERAKHIR</th>
									<th>JENIS CLAIM</th>
									<th>STATUS CLAIM</th>
								</tr>
							</thead>
							<tbody @click="handleClick">
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth, openChat, charsRemover, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import Historyfilter from "@/dialog/Historyfilter";
import FormClaim from "@/dialog/FormClaim";
import FormClosingClaim from "@/dialog/FormClosingClaim";
import moment from "moment";
import "icheck-material";
export default {
	name: "Riwayat",
	data() {
		return {
			dateStart: '',
			dateEnd: '',
			errors: [],
			method: "",
			roles: "",
			groups: [],
			formTitle: "Tambah User",
			form: {
				name: "",
				username: "",
				email: "",
			},
			groupId: '',
			downloading: false,
			show: false,
			filter: {},
			data_type: "pickupTime",
			dt1: moment().startOf("month"),
			dt2: moment(),
			selectedCount: 0,
			totalItems: 0,
			totalPages: 0,
			isOpen: false,
			printBtnMessage: "",
			courier: [],
			urlDownload: "",
			downloading: false,
			templateOpt: 5,
			selectedItems: [],
			btnFilter: 'btn-default',
			deleting: false,
			processing: false,
			openClaim: false,
			showClosingClaim: false,
			waLink: '',
			loadingContent: false,
			trackingStatus: 0,
			textBatal: '',
			templateBatal: [
				'Alamat pengiriman saya salah',
				'Alamat penjemputan saya salah',
				'Detail pesanan saya salah',
				'Kurang cocok dengan ongkos kirimnya',
				'Ingin mengganti kurir pengiriman',
				'Indikasi fraud',
				'Alasan lainnya',
			],
			customBatal: false,
			colorBatal: 'dark',
			successBatal: false,
			hideTable: true,
            infoDownload: false
		};
	},
	watch: {
		$route(to, from) {
			if (to.path === "/riwayat/admin/all") {
				sessionStorage.filterData = '';
				sessionStorage.filterStatus = '';
				this.btnFilter = 'btn-default';
				this.filter = {};
				this.tablePiutang.api().ajax.reload();
			}
		},
		textBatal: function (val) {
			this.textBatal = charsRemover(val);
		},
	},
	computed: {
		kurirLogo() {
			const fileName = "jne"; //logo.toLowerCase()

			return require(`../assets/img/${fileName}.png`); // the module request
		},
	},
	components: {
		Historyfilter,
		FormClaim,
		FormClosingClaim
	},
	created: function () {
		this.roles = this.$route.meta.roles;
		this.userInfo = auth.user();
		if (this.$route.path === "/riwayat/admin/all") {
			sessionStorage.filterData = '';
			sessionStorage.filterStatus = '';
		}
		if (this.$route.params.filter)
			this.filter = this.$route.params.filter;
		if (this.filter.dt1 && this.filter.dt2) {
			this.dt1 = moment(this.filter.dt1);
			this.dt2 = moment(this.filter.dt2);
		}
		this.dateStart = this.dt1.format('YYYY-MM-DD');
		this.dateEnd = this.dt2.format('YYYY-MM-DD');
		if (this.filter.gid) {
			this.groupId = this.filter.gid;
			this.dateStart = '';
			this.dateEnd = '';
		}
		if (Object.keys(this.filter).length < 1) {
			if (sessionStorage.filterData) this.filter = JSON.parse(sessionStorage.filterData);
		} else {
			sessionStorage.filterData = JSON.stringify(this.filter);
			sessionStorage.filterStatus = 1;
		}
		this.filter.datatype = this.data_type;
		this.filter.dt1 = !this.filter.dt1 ? moment().startOf("month").format("YYYY-MM-DD") : this.filter.dt1;
		this.filter.dt2 = !this.filter.dt2 ? moment().format("YYYY-MM-DD") : this.filter.dt2;

		if (sessionStorage.filterStatus == 1) {
			this.btnFilter = 'btn-info';
		}
		
		authFetch("/users/groups")
			.then((res) => {
				if (res.status === 201) {} else if (res.status === 400) {}
				return res.json();
			})
			.then((js) => {
				this.groups = js.data;
			});
	},
	methods: {
		canDelete: function(){
			if(this.userInfo.role_id == 1 || this.userInfo.role_id == 2)
				return true;
			return false;
		},
		confirmAcceptReturn: function () {
			Swal.fire({
				title: "Konfirmasi",
				text: "Pastikan retur pesanan ini sudah diterima ?",
				showCancelButton: true,
				confirmButtonText: `Terima Retur`,
			}).then((result) => {
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) {
					this.acceptReturn();
				}
			});
		},
		closeClaimReturn: function () {
			var checkboxes = document.querySelectorAll('.select-order');
			var selected = [];
			for (var i = 0; i < checkboxes.length; i++) {
				if (checkboxes[i].checked){
					var exp = checkboxes[i].value.split(';');
					selected.push(exp[0]);
				}
				// if (checkboxes[i].checked) selected.push(checkboxes[i].value);
			}
			this.selectedItems = selected;
			//this.openClaim = true;
			this.showClosingClaim = !this.showClosingClaim;
		},
		acceptReturn: function (e) {
			var checkboxes = document.querySelectorAll('.select-order');
			var selected = [];
			for (var i = 0; i < checkboxes.length; i++) {
				if (checkboxes[i].checked){
					var exp = checkboxes[i].value.split(';');
					selected.push(exp[0]);
				}
				// if (checkboxes[i].checked) selected.push(checkboxes[i].value);
			}
			this.processing = true;
			authFetch(
					"/order/return_accept", {
						method: 'POST',
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
						body: JSON.stringify(selected)
					}
				)
				.then((response) => response.json())
				.then((json) => {
					this.processing = false;
					this.tablePiutang.api().ajax.reload();
				});
		},
		claimReturn: function () {
			if (this.filter.delgo>0) this.trackingStatus = 1;
			else if (this.filter.delok>0) this.trackingStatus = 2;
			else if (this.filter.retgo>0) this.trackingStatus = 3;
			else if (this.filter.retok>0) this.trackingStatus = 4;
			else if (this.filter.deler>0) this.trackingStatus = 5;
			else if (this.filter.pbreach>0) this.trackingStatus = 6;

			var checkboxes = document.querySelectorAll('.select-order');
			var selected = [];
			for (var i = 0; i < checkboxes.length; i++) {
				if (checkboxes[i].checked){
					var exp = checkboxes[i].value.split(';');
					selected.push(exp[0]);
				}
				// if (checkboxes[i].checked) selected.push(checkboxes[i].value);
			}
			this.selectedItems = selected;
			//this.openClaim = true;
			this.openClaim = !this.openClaim;
		},
		infoClaim(claim){
			var statusClaim = '';
			if (claim == 1)
				statusClaim = '<span class="badge badge-info">Paket Diclaim</span>';
			else if (claim == 2)
				statusClaim = '<span class="badge badge-success">Claim disetujui</span>';
			else if (claim == 3)
				statusClaim = '<span class="badge badge-danger">Claim ditolak</span>';
			return statusClaim;
		},
		infoStatus(track) 
		{
			var output = '';
			if (track.cancel == 1)
				output += '<span class="badge badge-danger">Cancel</span>';
			else if (track.pickupStatus == 0)
				output += '<span class="badge badge-inverse">DRAFT</span>';
			else
			{
				if (track.trackingStatus == 0)
				{
					output += '<span class="badge badge-secondary">Menunggu Pickup</span>';
					output += this.infoClaim(track.retClaim);
				}
				else if (track.trackingStatus == 1)
				{
					output += '<span class="badge badge-warning">Proses</span>';
					output += this.infoClaim(track.retClaim);
				}
				else if (track.trackingStatus == 2)
				{
					output += '<span class="badge badge-success">Terkirim</span>';
					output += this.infoClaim(track.retClaim);
				}
				else if (track.trackingStatus == 3)
				{
					output += '<span class="badge badge-warning">Proses Retur</span>';
					output += this.infoClaim(track.retClaim);
				}
				else if (track.trackingStatus == 4) {
					if (track.retStatus == 1) 
					{
						output += '<span class="badge badge-success">Retur Diterima</span>';
						output += this.infoClaim(track.retClaim);
					}
					else if (!track.retStatus)
					{
						output += '<span class="badge badge-info">Retur Diserahkan</span>';
						output += this.infoClaim(track.retClaim);
					}
				}
				else if (track.trackingStatus == 5) 
				{
					output += '<span class="badge badge-danger">Bermasalah</span>';
					output += this.infoClaim(track.retClaim);
				}
			}
			return output;
		},
		cancelOrder: function () {
			this.successBatal = false;
			const swalConfirmButtons = Swal.mixin({
				customClass: {
				title: 'h4',
				actions: 'd-flex justify-content-between flex-row-reverse',
				cancelButton: 'btn btn-lg btn-outline-primary w-49 font-weight-bold',
				confirmButton: 'btn btn-lg btn-primary w-49 font-weight-bold',
				},
				buttonsStyling: false
			});
			swalConfirmButtons.fire({
				title: "Apakah kamu yakin untuk membatalkan?",
				text: "Pastikan kurir belum melakukan pickup agar paketmu bisa dibatalkan",
				showCancelButton: true,
				cancelButtonText: `Tutup`,
				confirmButtonText: `Ya, batalkan`,
			}).then((result) => {
				const r = this.$refs;
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) {
					$(r.formCancelOrder).modal("show");
				}
			});
		},
		textBatalChange: function (index) {
			if (index == 5) {
				this.textBatal = '';
				this.customBatal = true;
			} else {
				this.textBatal = this.templateBatal[index];
				this.customBatal = false;
				this.colorBatal = 'dark';
			}
		},
		deleteOrder: function (e) {
			if (this.customBatal && this.textBatal.length < 25) {
				Swal.fire('Minimal 25 karakter');
				this.colorBatal = 'danger';
				e.preventDefault();
				return;
			} else if (this.textBatal == '') {
				Swal.fire('Pilih alasan pembatalan');
				e.preventDefault();
				return;
			}

			var checkboxes = document.querySelectorAll('.select-order');
			var selected = [];
			for (var i = 0; i < checkboxes.length; i++) {
				if (checkboxes[i].checked) {
					var exp = checkboxes[i].value.split(';');
					selected.push(exp[0]);
				}
				// if (checkboxes[i].checked) selected.push(checkboxes[i].value);
			}
			this.selectedItems = { group: selected, items: this.textBatal };
			this.deleting = true;
			authFetch(
					"/order/cancel", {
						method: 'POST',
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
						body: JSON.stringify(this.selectedItems)
					}
				)
				.then((response) => response.json())
				.then((json) => {
					this.deleting = false;
					if (json.success) {
						this.textBatal = '';
						this.customBatal = false;
						this.colorBatal = 'dark';
						this.successBatal = true;
						this.tablePiutang.api().ajax.reload();
					} else {
						if (json.message) {
							Swal.fire(json.message);
						} else if (json.desc) {
							Swal.fire(json.desc);
						} else {
							Swal.fire('Gagal membatalkan pesanan');
						}
					}
				});
			e.preventDefault();
		},
		uncancelOrder: function () {
			Swal.fire({
				title: "Konfirmasi",
				text: "Uncancel Data Pesanan?",
				showCancelButton: true,
				confirmButtonText: `Lanjutkan`,
			}).then((result) => {
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) {
					this.undeleteOrder();
				}
			});
		},
		undeleteOrder: function (e) {
			var checkboxes = document.querySelectorAll('.select-order');
			var selected = [];
			for (var i = 0; i < checkboxes.length; i++) {
				if (checkboxes[i].checked){
					var exp = checkboxes[i].value.split(';');
					selected.push(exp[0]);
				}
				// if (checkboxes[i].checked) selected.push(checkboxes[i].value);
			}
			this.selectedItems = selected;
			this.deleting = true;
			authFetch(
					"/order/uncancel", {
						method: 'POST',
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
						body: JSON.stringify(this.selectedItems)
					}
				)
				.then((response) => response.json())
				.then((json) => {
					this.deleting = false;
					this.tablePiutang.api().ajax.reload();
				});
		},
		startPrint: function (e) {
			var checkboxes = document.querySelectorAll('.select-order');
			var selected = [];
			for (var i = 0; i < checkboxes.length; i++) {
				if (checkboxes[i].checked){
					var exp = checkboxes[i].value.split(';');
					selected.push(exp[0]);
				}
				// if (checkboxes[i].checked) selected.push(checkboxes[i].value);
			}
			this.selectedItems = selected;
			const r = this.$refs;
			this.totalPages = 1;
			this.totalItems = selected.length;
			if (this.totalItems > 50){
					Swal.fire("Proses gagal", `Maksimal download 50 resi.`, "error");
				return;
			}
			this.isOpen = false;
			$(r.formPrint).modal("show");
		},
		startPrintInvoice: function (e) {
			var checkboxes = document.querySelectorAll('.select-order');
			var selected = [];
			for (var i = 0; i < checkboxes.length; i++) {
				if (checkboxes[i].checked){
					var exp = checkboxes[i].value.split(';');
					selected.push(exp[0]);
				}
				// if (checkboxes[i].checked) selected.push(checkboxes[i].value);
			}
			this.selectedItems = selected;
			const r = this.$refs;
			this.totalPages = 1;
			this.totalItems = selected.length;
			this.isOpen = false;
			$(r.formPrintInvoice).modal("show");
		},
		selectAll: function (e) {
			var val = e.target.checked;
			var checkboxes = document.querySelectorAll('.select-order');
			for (var i = 0; i < checkboxes.length; i++) {
				var exp = checkboxes[i].value.split(';');
				this.courier = exp[1];
				checkboxes[i].checked = val;
			}
			if (val && checkboxes.length > 0)
				this.selectedCount = checkboxes.length;
			else this.selectedCount = 0;
			if (this.selectedCount > 0) $(this.$refs.btnSelected).show();
			else $(this.$refs.btnSelected).hide();
		},
		downloadLabel() {
			this.printBtnMessage = "Proses...";
			localStorage.defaultTpl = this.templateOpt;
			this.downloading = true;
			if (this.templateOpt < 5) {
				var nPage = parseInt(this.totalItems / this.templateOpt);
				this.totalPages =
					this.totalItems % this.templateOpt == 0 ? nPage : nPage + 1;
			} else {
				this.totalPages = this.totalItems;
			}
			authFetch(
					"/report/cetakresi/items?template=" +
					this.templateOpt, {
						method: 'POST',
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
						body: JSON.stringify(this.selectedItems)
					}
				)
				.then((response) => response.blob())
				.then((blob) => {
					setTimeout(() => {
						this.downloading = false;
					}, 1000);
					var url = window.URL.createObjectURL(blob);
					this.urlDownload = url;
					this.printBtnMessage = "Download";
				});
		},
		downloadInvoice() {
			this.printBtnMessage = "Proses...";
			localStorage.defaultTpl = this.templateOpt;
			this.downloading = true;
			if (this.templateOpt < 5) {
				var nPage = parseInt(this.totalItems / this.templateOpt);
				this.totalPages =
					this.totalItems % this.templateOpt == 0 ? nPage : nPage + 1;
			} else {
				this.totalPages = this.totalItems;
			}
			authFetch(
					"/report/cetakresi/items?template=8", {
						method: 'POST',
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
						body: JSON.stringify(this.selectedItems)
					}
				)
				.then((response) => response.blob())
				.then((blob) => {
					setTimeout(() => {
						this.downloading = false;
					}, 1000);
					var url = window.URL.createObjectURL(blob);
					this.urlDownload = url;
					this.printBtnMessage = "Download";
					//this.printLabel();
				});
		},
		printLabel() {
			var a = document.createElement("a");
			// a.href = this.urlDownload;

			if (window.navigator.userAgent.match(/Mobile/i)){
				a.href = window.open(this.urlDownload);
			} else {
				a.href = this.urlDownload;
			}
			a.download = "Data_Label-" + moment().format("YYMMDDHHmmss") + ".pdf";
			document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
			a.click();
			a.remove();
		},
		templateChange(e) {
			this.downloadLabel();
		},
		downloadXlsx: function (e) {
			this.downloading = true;
			var table = $('#tblpiutang').DataTable();
			var length = table.page.info().recordsTotal;
			
			if (length > 20000) {
				Swal.fire("Proses gagal", `Maksimal data AWB sebanyak kurang lebih 20.000 data.`, "error");
				this.downloading = false;
				return false;
			}
			
			var filter = JSON.parse(JSON.stringify(this.filter));
                filter.q = this.tablePiutang ? $("input[name=q]").val() : '';
                filter.category = this.tablePiutang ? $("select[name=category]").val() : 0;

			console.log(filter);
			var data = Object.keys(filter)
				.map(
					(key) =>
					encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
				)
				.join("&");
			console.log(data)
			this.loadingContent = true;
			authFetch("/report/order/excell_admin", {
					method: "POST",
					body: data,
				})
				.then((response) => {
					if (response.status === 201) {
					} else if (response.status === 400) {
					} else if (response.status === 500){
						Swal.fire("Timeout!", ``, "error");
						this.loading2 = false;
						this.msg = '';
						return false;
					}

					return response.blob();
				})
				.then((blob) => {
					setTimeout(() => {
						this.downloading = false;
					}, 1000);
					this.loadingContent = false;
					var url = window.URL.createObjectURL(blob);
					var a = document.createElement("a");
					a.href = url;
					a.download = "data.xlsx";
					document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
					a.click();
					a.remove(); //afterwards we remove the element again
				});
		},
		// downloadCsv: function () {
		// 	this.downloading = true;
		// 	authFetch("/report/piutang/csv/"+this.filter.dt1, {
		// 		method: "GET",
		// 	})
		// 		.then((response) => response.blob())
		// 		.then((blob) => {
		// 			this.downloading = false;
		// 			var url = window.URL.createObjectURL(blob);
		// 			var a = document.createElement("a");
		// 			a.href = url;
		// 			a.download = "PIUTANG-PER"+ this.filter.dt1 +".csv";
		// 			document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
		// 			a.click();
		// 			a.remove(); //afterwards we remove the element again
		// 		});
		// },
		downloadCsv: function (e) {
            const self = this;
            self.downloading = true;
            self.loadingContent = true;
            self.infoDownload = false;

            var data = {
                "module_code": 'piutang',
                "filter": self.filter,
                "title": 'piutang',
                "note": JSON.stringify(self.filter),
            };

            authFetch("/accounting/jurnal/request_download", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
            .then((res) => {
                setTimeout(() => {
                    self.downloading = false;
                }, 1000);
                self.loadingContent = false;
                if (res){
                    self.infoDownload = true;
                }
            });
        },
		reloadData: function (e) {
			this.tablePiutang.api().ajax.reload();
		},
		updateFilter: function (e) {
			this.filter = e;
			this.tablePiutang.api().ajax.reload();
		},
		dataType(event) {
			this.data_type = event.target.value;
			this.filter.datatype = this.data_type;
			this.tablePiutang.api().ajax.reload();
		},
		orderType(event) {
			this.filter.order_type = event.target.value;
            if (this.tablePiutang) {
                this.tablePiutang.api().ajax.reload();
            } else {
                // this.showTable();
            }
		},
		onDate() {
            if (this.tablePiutang) {
                this.tablePiutang.api().ajax.reload();
            } else {
                // this.showTable();
            }
		},
		selectStatus: function () {
			this.show = !this.show;
		},
		getKurirLogo(kurir) {
			const fileName = kurir.toLowerCase();

			return require(`../assets/img/${fileName}.png`); // the module request
		},
		dateDiff(dt1, dt2){
			let d1 = new Date(dt1);
			let d2 = new Date(dt2);

			const difference = d2.getTime() - d1.getTime();
			const days = Math.round(difference / (1000 * 60 * 60 * 24));
			return days;
		},
		handleClick(e) {
			if (e.target.matches(".link-order")) {
				let route = this.$router.resolve({
					path: "/order/detail/" + e.target.dataset.id
				});
				window.open(route.href,"_blank");
				// return false;
			}
			else if (e.target.matches(".link-chat")) {
				openChat(e.target.dataset.to);
				return false;
			} 
			else if (e.target.matches("input[type=checkbox]")) {
				var checkboxes = document.querySelectorAll('.select-order:checked');
				if (checkboxes.length == 0) {
					this.$refs.checkall.checked = false;
				} else {
					this.$refs.checkall.checked = true;
					var selected = [];
					for (var i = 0; i < checkboxes.length; i++) {
						if (checkboxes[i].checked){
							var exp = checkboxes[i].value.split(';');
							this.courier = exp[1];
							console.log('all',exp);
							if(this.courier == 'JNE'){
								console.log('masuk');
							}
							console.log('keluar');
							console.log(this.courier);
							selected.push(exp[0]);
						}
					}
					this.selectedItems = selected;
				}
				this.selectedCount = checkboxes.length;
				if (this.selectedCount > 0) $(this.$refs.btnSelected).show();
				else $(this.$refs.btnSelected).hide();
				return false;
			}
		},
		showTable: function () {
			if (this.tablePiutang) {
				this.tablePiutang.api().ajax.reload();
			} else {
				this.hideTable = false;
				const e = this.$refs;
				var self = this;
				this.tablePiutang = createTable(e.tablepiutang, {
					title: "",
					scrollX: true,
					roles: this.$route.params.roles,
					ajax: "/report/piutang/new",
					frame: false,
					toolbarEl: e.toolbar,
					lengthChange: true,
					filter: true,
					stateSave: true,
					dom: "<'row'<'col-sm-12 col-md-6 btn-area-selected'><'col-sm-12 col-md-6'f>>" +
						"<'row'<'col-sm-12'tr>>" +
						"<'row'<'col-sm-12 col-md-2'l><'col-sm-12 col-md-3'i><'col-sm-12 col-md-7'p>>",
					processing: true,
					language: {
						processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'
					},
					filterBy: [0, 1, 11],
					paramData: function (d) {
						var filter = JSON.parse(JSON.stringify(self.filter));
						for (const key in filter) {
							d[key] = filter[key] === false ? 0 : filter[key];
						}
					},
					"order": [
						[2, 'desc']
					],
					columns: [
						{
							data: "no_resi"
						},
						{
							data: "prefix",
							render: function (data, type, row, meta) {
								return data;
							},
						},
						{
							data: "journal_date",
							render: function (data, type, row, meta) {
								return !data ? '-' : moment(data).format('DD/MM/YYYY');
							},
						},
						{
							data: "nilai_cod",
							render: function (data, type, row, meta) {
								return formatCurrency(data);
							},
						},
						{
							data: "debit_awal",
							render: function (data, type, row, meta) {
								return formatCurrency(data);
							},
						},
						{
							data: "total_debit_piutang",
							render: function (data, type, row, meta) {
								return formatCurrency(data);
							},
						},
						{
							data: "pembayaran",
							render: function (data, type, row, meta) {
								return formatCurrency(data);
							},
						},
						{
							data: "credit_awal",
							render: function (data, type, row, meta) {
								return formatCurrency(data);
							},
						},
						{
							data: "total_credit_piutang",
							render: function (data, type, row, meta) {
								return formatCurrency(data);
							},
						},
						{
							data: "sisa_tagihan",
							render: function (data, type, row, meta) {
								return !data ? '-' : formatCurrency(data);
							},
						},
						{
							data: "aging",
							render: function (data, type, row, meta) {
								return data;
							},
						},
						{
							data: "shipper_name",
							render: function (data, type, row, meta) {
								return data;
							},
						},
						{
							data: "status_cod",
							render: function (data, type, row, meta) {
								return data;
							},
						},
						{
							data: "note",
							render: function (data, type, row, meta) {
								return data;
							},
						},
						{
							data: "item_value",
							render: function (data, type, row, meta) {
								return formatCurrency(data);
							},
						},
						{
							data: "tracking_statuss",
							render: function (data, type, row, meta) {
								return data;
							},
						},
						{
							data: "return_claim_code",
							render: function (data, type, row, meta) {
								return data;
							},
						},
						{
							data: "return_claim",
							render: function (data, type, row, meta) {
								return data;
							},
						},
					],
					rowCallback: function (row, data) {
					},
					drawCallback: function (row, data) {
					},
				});
			}
		}
	},
	mounted() {
		this.$gtag.event(`page_view`, {
			'event_name': 'Laporan Piutang'
		});
		const e = this.$refs;
		const self = this;

		new Daterangepicker(
			this.$refs.daterange, {
				startDate: this.dt1,
				endDate: this.dt2,
				singleDatePicker: true,
				showDropdowns: true,
				locale: {
					format: 'DD/MM/YYYY'
				},
			},
			function (d1, d2) {
				self.dateStart = d1.format("YYYY-MM-DD");
				self.dateEnd = d2.format("YYYY-MM-DD");
				self.data_type = self.data_type;
				self.filter.datatype = self.data_type;
				self.filter.dt1 = self.dateStart;
				self.filter.dt2 = self.dateEnd;
				self.onDate();
			}
		);
		$('.loading-overlay').removeClass('show');
	},
};
</script>
<style scoped>
.dataTables_length{padding-top: 0.5em;}
</style>